<div class="container py-5">
    <h2 class="text-center" style="font-weight: bolder;">WEB PRIVACY POLICY</h2>
    <p class="fs-6 mt-3" style="font-weight: 600;">1. Premessa</p>
    <p>La presente Informativa (di seguito «Policy»), resa ai sensi degli artt. 13 e 14 del Regolamento (UE)
        27 aprile 2016 n. 679, è volta ad illustrare come lo <strong>Sci Club Bussoleno</strong> A.S.D. - P.IVA IT
        86504790014 (di seguito anche “Sci Club” tratta i dati personali raccolti nell’ambito dell’utilizzo da
        parte dell’utente, delle applicazioni e dei servizi offerti attraverso questo sito web. A tal proposito, ti
        informiamo che <strong>Sci Club Bussoleno</strong> in qualità di <strong>Titolare del Trattamento</strong>, è il
        principale
        responsabile nei confronti dell'utente per qualsiasi domanda, dubbio o reclamo in merito alla presente
        Policy o al trattamento dei dati personali.</p>
    <p>
        In caso tu, in qualità di Utente e soggetto «interessato» abbia bisogno di qualunque chiarimento in
        merito al trattamento dei tuoi dati personali, ti preghiamo di contattarci ai seguenti recapiti:
        Indirizzo: P.zza Don Aldo Grisa, 1 – 10053 Bussoleno (TO) - Italia
        Telefono: <a href="tel:3356948990">+39 335 6948990</a>
        PEC:
        e-mail: <a href="mailto:info@sciclubbussoleno.com">info@sciclubbussoleno.com</a>
        Responsabile Protezione Dati (RPD) o Data Protection Officer (DPO): <strong>NON NOMINATO</strong>
    </p>
    <p class="fs-6" style="font-weight: 600;">2. Quali dati personali trattiamo?</p>
    <p><span class="fs-6" style="font-weight: 600;">2.1 Dati forniti dall'utente.</span> In quanto utente puoi fornirci
        dati, compresi quelli che permettono la tua identificazione, "dati personali", quando utilizzi il nostro sito
        Web, ci contatti, richiedi nostri servizi. Qualora
        tali dati vengano da noi richiesti, raccoglieremo i dati indicati nei moduli o nelle pagine pertinenti.
        Come utente puoi scegliere di fornirci ulteriori informazioni quando ci contatti o interagisci in altro
        modo con noi.</p>
    <ul>
        <li>(a) Raccogliamo i tuoi dati personali quando comunichi con noi di persona, attraverso il nostro sito
            web, e-mail, per telefono o tramite qualsiasi altro mezzo. Ad esempio, raccogliamo i tuoi dati di
            contatto e i dettagli dei nostri messaggi a te e da te inviati (compresi i dati relativi a quando li hai
            inviati, quando li abbiamo ricevuti e in alcuni casi anche da dove li hai inviati).
        </li>
        <li> (b) Se sei un utente abituale del nostro sito web o desideri abbonarti a servizi specifici, potresti volere
            o dovere creare un Account Cliente ("Account"). Le informazioni fornite durante la configurazione
            dell'Account possono includere il nome, l'indirizzo e-mail, la ragione sociale, il contatto tuo o aziendale,
            i numeri di telefono e altri dati di contatto. Se ti registri al fine di creare un Account, otterrai
            una password univoca che ti permetterà di accedere al tuo Account e di utilizzare i servizi da te
            scelti.
        </li>
        <li>
            (c) Se ti iscrivi alla nostra newsletter o altre forme di comunicazione diretta da noi, potrebbe essere
            necessario fornire il proprio nome, indirizzo e-mail o altri specifici dati di volta in volta dichiarati.
        </li>
        <li>
            (d) In quanto utente puoi inoltre fornirci, anche attraverso gli appositi Form di acquisizione disponibili
            sulle pagine del sito, dati personali (quali il contenuto delle comunicazioni scambiate con noi e i
            dati di contatto) al fine di ricevere ulteriori informazioni e/o servizi. I dati saranno da noi trattati
            esclusivamente per le finalità strettamente connesse alla tua richiesta. L'eventuale rifiuto a fornire
            tali dati potrebbe incidere sulla tua possibilità di beneficiare del servizio o di ricevere le informazioni
            richieste.</li>
    </ul>
    <p class="mt-3"><span class="fs-6" style="font-weight: 600;">2.2 I dati che raccogliamo automaticamente.</span>
        Quando visiti il
        nostro sito web, possiamo raccogliere
        automaticamente alcuni dati personali impliciti nel protocollo di rete. Si tratta di informazioni che sono
        raccolte non per essere associate ad interessati identificati, ma che, per loro stessa natura, potrebbe ro,
        mediante elaborazioni ed associazioni con dati detenuti da terzi, permettere l’identificazione degli
        utenti del sito.
    </p>
    <ul>
        <li>(a) In tale categoria di dati rientrano gli indirizzi IP o i nomi a dominio dei computer utilizzati dagli
            utenti che si connettono al sito, gli indirizzi URL delle risorse richieste, l’orario della richiesta, il
            metodo utilizzato nel sottoporre la richiesta al server, il nome e la dimensione del file ottenuto in
            risposta, il codice numerico indicante lo stato della risposta data dal server (buon fine, errore, ecc.)
            ed altri parametri relativi al sistema operativo e all’ambiente informatico dell’utente come ad esmpio i
            dati di accesso, tipo e versione del browser, tipi e versioni di plug-in del browser, sistema
            operativo e piattaforma, dati relativi alla visita, incluso il clickstream URL attraverso e dal nostro
            sito web, articoli visualizzati o cercati, errori di download, tempi e durata delle visite a determinate
            pagine e l'interazione della pagina. Raccogliamo dati automaticamente attraverso l'uso di varie
            tecnologie, anche attraverso i "cookie" (vedi il <strong>paragrafo 7</strong> della presente Policy).
        </li>
        <li>
            (a) Raccogliamo inoltre dati attraverso i nostri sistemi di sicurezza (ad esempio Antivirus e Firewall)
            durante la tua navigazione sulle nostre pagine web o l’utilizzo delle nostre applicazioni cloud. </li>
    </ul>
    <p class="mt-3"><span class="fs-6" style="font-weight: 600;">2.3 Dati che riceviamo da altre fonti.</span> Possiamo
        ricevere dati
        relativi alla tua persona da altre fonti se utilizzi uno qualsiasi degli altri servizi che forniamo su questo
        sito web. Lavoriamo anche a stretto contatto con terzi (inclusi, ad esempio, partner commerciali, subappaltatori
        di servizi tecnici, di pagamento e
        di consegna, fornitori di analisi) e potremmo ricevere da loro dati concernenti la tua persona (inclusi i
        dati che sono pubblicamente disponibili).
    </p>
    <p><span class="fs-6" style="font-weight: 600;">2.4 Categorie particolari di dati personali.</span> Potremmo
        ricevere da te, o da altre fonti, dati che rientrano
        nella definizione di “categorie particolari di dati”. Questo potrebbe accadere ad esempio, su tuo invio
        volontario agli indirizzi di posta elettronica indicati sul sito oppure nelle sezioni dedicate ai commenti
        degli utenti.
    </p>
    <p style="font-weight: 600;">3. Come trattiamo i dati</p>
    <p><span class="fs-6" style="font-weight: 600;">3.1</span>Trattiamo i dati che raccogliamo per offrirti la migliore
        esperienza possibile quando accedi al nostro
        sito web e interagisci con noi. Ciò include il trattamento dei tuoi dati, in quanto utente, per i seguenti
        scopi:
    </p>
    <ul>
        <li>(a) <strong>fornire e migliorare i nostri servizi</strong>: Trattiamo i tuoi dati per migliorare i nostri
            servizi in modo da
            soddisfare al meglio le esigenze di tutti i nostri clienti;
        </li>
        <li>(b) <strong>creazione e gestione del tuo Account</strong>: trattiamo i tuoi dati per la creazione del tuo
            Account, per
            creare il profilo sui nostri sistemi e gestire il nostro rapporto con te. Trattiamo i tuoi dati anche per
            comunicare con te riguardo al tuo Account e ai nostri servizi;</li>
        <li>(c) <strong>amministrazione del sito we</strong>: trattiamo i tuoi dati per gestire il nostro sito web per
            operazioni
            interne, inclusa la risoluzione di problemi e per comprendere eventuali errori riscontrati durante
            l'utilizzo del nostro sito web;</li>
        <li>(d) <strong>sicurezza</strong>: trattiamo i dati raccolti sul nostro sito web per mantenere lo stesso sicuro
            e per gestire
            e amministrare i nostri sistemi IT;
        </li>
        <li>(e) <strong>marketing</strong>: trattiamo i tuoi dati per comunicare con te e per tenerti aggiornato sulle
            nostre attività
            e su quelle delle Società a cui potresti essere interessato e per fornire a te e agli altri utenti del
            nostro sito suggerimenti e raccomandazioni su servizi che potrebbero essere oggetto d’interesse.
        </li>
        <li>(f) <strong>pubblicità online</strong>: utilizziamo i tuoi dati per misurare o comprendere l'efficacia della
            pubblicità
            che proponiamo a te e agli altri utenti, e per proporti pubblicità pertinente. Utilizziamo i tuoi dati
            anche per comunicare con te attraverso i social media al fine di sviluppare il nostro business anche sui
            social media. Precisiamo che le informazioni che ci rilascerai volontariamente sui social,
            saranno utilizzate esclusivamente per le finalità previste nella presente informativa.
        </li>
        <li>
            (g) <strong>reclutamento</strong>: per lavorare nel nostro Sci Club, accettiamo candidature online inviate
            attraverso
            e-mail. Il nostro Sci Club riceverà i dati presenti nella candidatura stessa e nella documentazione
            ad essa allegata (tra cui eventualmente curriculum vitae e lettera di presentazione). Tratteremo i
            tuoi dati personali solo allo scopo di portare avanti il processo di selezione, valutare e prendere
            una decisione in merito alla tua idoneità per il ruolo, comunicare con te ed effettuare eventuali
            controlli. Tratteremo i tuoi dati anche per ottemperare agli obblighi di legge;
        </li>
        <li>
            (h) <strong>per effettuare le necessarie comunicazioni:</strong> possiamo trattare i tuoi dati al fine di
            effettuare le
            necessarie comunicazioni in risposta alle richieste che siamo legalmente tenuti a soddisfare, alle
            forze dell'ordine o autorità giudiziaria o in difesa di un diritto.
        </li>
    </ul>
    <p class="fs-6 mt-3" style="font-weight: 600;">4. Perché trattiamo i tuoi dati?</p>
    <p><span class="fs-6" style="font-weight: 600;">4.1</span> Il nostro trattamento dei dati personali si fonda sulle
        seguenti basi giuridiche:</p>
    <ul>
        <li>(a) <strong> la necessità di assolvere adempimenti contrattuali o precontrattuali:</strong> ciò include, ad
            esempio: (1)
            concludere la fornitura dei nostri servizi; (2) garantire l’accesso alle aree riservate o alle
            applicazioni in cloud; (3) gestire i pagamenti;</li>
        <li>(b) <strong>la necessità del trattamento per il perseguimento dei nostri interessi legittimi:</strong> ciò
            include,
            ad esempio: (1) fornire i nostri servizi ai nostri utenti; (2) garantire che il tuo account sia
            adeguatamente protetto e garantire che il nostro sito web, la rete e la nostra infrastruttura IT siano
            sicuri
            e vengano utilizzati in modo appropriato; (3) meglio comprendere come i nostri servizi e sito web
            vengono utilizzati e apportare miglioramenti; (4) ricercare e analizzare i servizi desiderati; (5)
            adattare i nostri contenuti per l’utente; (6) garantire la sicurezza della nostra attività e dei nostri
            partner; e (7) sviluppare e mantenere relazioni con fornitori, partner, altre società e con le persone che
            lavorano per loro;
        </li>
        <li>(c) <strong>la necessità di adempiere a un obbligo legale:</strong> ad esempio, potrebbe essere necessario
            divulgare i tuoi
            dati su richiesta di un’autorità governativa o giurisdizionale competente;
        </li>
        <li>(d) <strong>qualora tu abbia fornito il tuo consenso:</strong> ad esempio, potremmo richiedere il tuo
            consenso
            quando si utilizzano tecnologie quali i cookie.
        </li>
    </ul>
    <p class="mt-3"><span class="fs-6" style="font-weight: 600;">4.2</span> Per ulteriori informazioni sui nostri
        interessi legittimi al trattamento dei dati personali, ti preghiamo di contattarci tramite le informazioni che
        ti abbiamo fornito nella sezione <strong>1.</strong></p>
    <p><span class="fs-6" style="font-weight: 600;">4.3</span> Se in quanto utente non sei in grado o non desideri
        fornire i dati personali di cui abbiamo bisogno per l’esecuzione del contratto o per l’adempimento di un obbligo
        previsto dalla legge, ciò può determinare l’impossibilità di soddisfare la tua richiesta.</p>
    <p class="fs-6 mt-3" style="font-weight: 600;">5. Chi può conoscere i tuoi dati?</p>
    <p><span class="fs-6" style="font-weight: 600;">5.1</span> I tuoi dati saranno trattati da nostri dipendenti e
        collaboratori nella loro qualità di Autorizzati al trattamento o da terzi fornitori di servizi cui la
        comunicazione sia necessaria o funzionale per la fornitura
        dei servizi offerti attraverso il nostro sito web. <br>
        A tal proposito ti informiamo che possiamo condividere i tuoi dati con terzi quando: (1) è necessario
        coinvolgere un terzo fornitore di servizi al fine di facilitare o estendere i nostri servizi; (2) quando da te
        esplicitamente richiesto; (3) quando richiesto da un provvedimento dell’autorità giudiziaria o da qualsiasi
        obbligo di legge o di regolamento; (4) al fine di far rispettare i nostri contratti e, in caso di reclamo
        legale, qualora richiesto, i dati potranno essere trasferiti per difenderci da tale reclamo, (5) per garantire
        la sicurezza dei nostri utenti, clienti e dei terzi, e (6) per proteggere i nostri diritti e proprietà e i
        diritti
        e proprietà dei nostri clienti e di terzi.
    </p>

    <p><span class="fs-6" style="font-weight: 600;">5.2</span> Si considerano terzi i soggetti che ci forniscono un
        servizio o che agiscono come nostri rappresentanti,
        come, a titolo esemplificativo e non esaustivo, subappaltatori (compresi i loro rappresentanti), consulenti
        professionali, fornitori IT, fornitori di database, specialisti di backup e servizi di Disaster Recovery,
        fornitori di servizi e-mail e altri fornitori di servizi necessari per migliorare i nostri servizi.
    </p>
    <p><span class="fs-6" style="font-weight: 600;">5.3</span> I nostri fornitori e prestatori di servizi saranno tenuti
        a rispettare i nostri standard in materia di trattamento dei dati e di sicurezza. I dati che comunichiamo,
        compresi i tuoi dati personali, saranno forniti
        solo in relazione e per quanto strettamente necessario allo svolgimento della loro funzione. Non sarà
        consentito loro di utilizzare i tuoi dati personali per scopi diversi da quelli indicati nella presente
        informativa.
    </p>

    <p class="fs-6" style="font-weight: 600;">6. Come attivare o disattivare il marketing diretto</p>
    <p><span class="fs-6" style="font-weight: 600;">6.1</span> possiamo utilizzare i dati da te forniti per scopi di
        marketing diretto al fine di inviare aggiornamenti,
        newsletter o altre comunicazioni che riteniamo possano essere di tuo interesse.
    </p>
    <p><span class="fs-6" style="font-weight: 600;">6.2</span> Laddove richiesto dalla legge, l’invio di messaggi di
        posta elettronica a carattere commerciale avverrà
        solamente previa acquisizione del tuo consenso. In ogni caso, ti offriamo la possibilità di annullare
        l'iscrizione ad ogni comunicazione inviata.
    </p>
    <p><span class="fs-6" style="font-weight: 600;">6.3</span> In quanto utente puoi opporti in qualsiasi momento alla
        ricezione di informazioni a carattere commerciale da parte nostra contattandoci attraverso le informazioni che
        ti abbiamo fornito nella sezione <strong>1</strong> o
        selezionando l'opzione di annullamento dell'iscrizione presente nelle nostre comunicazioni.
    </p>

    <p class="fs-6" style="font-weight: 600;">7. Cookie e altre tecnologie</p>
    <p><span class="fs-6" style="font-weight: 600;">7.1</span> Raccogliamo automaticamente dati attraverso l'uso di
        "cookies". Un cookie è un file di testo contenente piccole quantità di dati che un sito web può inviare al
        browser, che possono poi essere memorizzati
        sul tuo computer come un tag che distingue il tuo computer ma non ti identifica. Alcune delle nostre
        pagine del sito Web utilizzano i cookie per offrirti un miglior servizio durante i successivi utilizzi del sito
        Web. Puoi impostare il tuo browser in modo da ricevere una notifica prima di ricevere un cookie, in
        maniera tale da avere la possibilità di decidere se accettarlo o meno. È anche possibile impostare il
        browser per disattivare i cookie; tuttavia, se si esegue questa operazione, alcuni dei nostri siti Web
        potrebbero non funzionare correttamente. Utilizziamo inoltre tecnologie di tracciamento dei pixel nelle
        nostre e-mail per verificare i clic su link o immagini incorporate e l'apertura delle e-mail delle newsletter.
        Tali dati sono raccolti al fine di ottenere dati statistici sul nostro sito web e sulle nostre e-mail, nonché
        per identificare le caratteristiche e i contenuti preferiti da ciascun utente, al fine di inviare informazioni
        personalizzate
    </p>
    <p><span class="fs-6" style="font-weight: 600;">7.2</span> Per ulteriori informazioni sui cookie utilizzati su
        questo sito Web, ti invitiamo a consultare la nostra Cookie Policy mediante l’apposito link in calce ad ogni
        pagina del sito. Ulteriori informazioni di carattere
        generale sui cookie e su come tutelare i tuoi dati sono disponibili al seguente link: <a
            href="https://www.garanteprivacy.it/cookie" target="_blank">https://www.garanteprivacy.it/cookie</a>
    </p>

    <p class="fs-6" style="font-weight: 600;">8. Link ad altri siti web e social media</p>
    <p><span class="fs-6" style="font-weight: 600;">8.1</span> Laddove forniamo link di altri siti Web lo facciamo solo
        ed esclusivamente a scopo informativo. Gli altri
        siti web sono al di fuori del nostro controllo e la presente Policy non trova applicazione. Se accedi ad
        altri siti web utilizzando i link che abbiamo fornito, gli operatori di questi siti potranno raccogliere i tuoi
        dati ed utilizzarli in conformità alla loro Privacy Policy, che può differire dalla presente Policy.
    </p>

    <p class="fs-6" style="font-weight: 600;">9. Conservazione dei dati</p>
    <p><span class="fs-6" style="font-weight: 600;">9.1</span> I tuoi dati non verranno conservati per un periodo
        superiore a quello necessario per i nostri scopi commerciali o per l’adempimento degli obblighi legali.</p>
    <p><span class="fs-6" style="font-weight: 600;">9.2</span> Conserveremo i tuoi dati durante tutto il periodo in cui
        sarà attivo il tuo Account e per un periodo successivo alla chiusura dello stesso; ad esempio, nel caso in cui
        sia necessario per adempiere agli obblighi imposti dalla legge, come la conservazione dei dati per fini
        civilistici.</p>
    <p><span class="fs-6" style="font-weight: 600;">9.3</span> Conserveremo una copia dei tuoi dati di contatto in caso
        di opposizione o rifiuto a ricevere comunicazioni di marketing diretto da parte nostra. Aggiungeremo i tuoi dati
        alla nostra lista di cancellazione per
        assicurarti di non ricevere più alcuna comunicazione di marketing da parte nostra. Inoltre, non cancelleremo i
        dati personali se rilevanti per un'indagine o una controversia. I dati continueranno ad essere conservati fino a
        quando tali questioni non saranno state completamente risolte.
    </p>

    <p class="fs-6" style="font-weight: 600;">10. Diritti dell'utente</p>
    <p><span class="fs-6" style="font-weight: 600;">10.1</span> In conformità alla legge applicabile, in determinate
        circostanze e in relazione ai propri dati personali
        potrai esercitare i seguenti diritti. Prima di rispondere alla eventuale richiesta, ci riserviamo la possibilità
        di verificare l’identità del richiedente e di avere ulteriori dettagli relativi alla richiesta.
    </p>
    <ul>
        <li>(a) <strong>Diritto di accesso ai dati personali.</strong> Hai il diritto di ottenere la conferma che sia o
            meno in corso, da
            parte nostra, un trattamento di dati personali che ti riguardano e in tal caso, di ottenere
            l'accesso ai dati personali trattati. Hai il diritto di ottenere una copia dei dati oggetto del trattamento.
            Tale diritto è applicabile solo se non porta a ledere i diritti e le libertà altrui. Sul punto si precisa
            che nel caso di una tua richiesta di ulteriori copie, ti potrà essere da noi addebitato un contributo spese
            basato sui nostri costi amministrativi.
        </li>
        <li>(b) <strong>Diritto di rettificare, cancellare o limitare il trattamento dei dati personali.</strong> Se
            desideri
            rettificare, cancellare o limitare il trattamento dei tuoi dati personali, ti invitiamo a contattarci
            mediante
            le informazioni che ti abbiamo fornito nella sezione 1. È tua responsabilità assicurarti di fornire
            dati veritieri, accurati, completi e di mantenerli aggiornati.
        </li>
        <li>(c) <strong>Diritto di revocare il consenso.</strong> Se ci hai fornito il consenso al trattamento dei tuoi
            dati, puoi
            revocarlo in qualsiasi momento
        </li>
        <li>(d) <strong>Diritto alla portabilità dei dati.</strong> Se il trattamento si basa sul tuo consenso o
            contratto ed è
            effettuato con mezzi elettronici, tu hai il diritto di ricevere in un formato strutturato, di uso comune e
            leggibile da dispositivo automatico, i dati personali che ti riguardano a noi forniti e hai il diritto di
            trasmettere tali dati a un altro titolare del trattamento senza impedimenti da parte nostra
        </li>
        <li>(e) <strong>Diritto di opposizione.</strong> In quanto utente hai il diritto di opporti al trattamento dei
            tuoi dati in
            determinate circostanze. Ad esempio, puoi beneficiare di tale diritto se il trattamento si fonda su nostri
            interessi legittimi (o su quelli di terzi). Ti è possibile contestare la fondatezza dei nostri interessi
            legittimi, tuttavia, potremmo avere il diritto di continuare a trattare tali dati personali sulla base
            dei nostri interessi legittimi o quando ciò sia rilevante in relazione ad azioni legali, oppure i dati
            siano necessari per l'accertamento, l'esercizio o la difesa di un diritto in sede giudiziaria. Hai inoltre
            il diritto di opporti al trattamento dei tuoi dati personali per finalità di marketing diretto.
        </li>
        <li>(f) <strong>Diritto di non essere sottoposto ad un processo decisionale automatizzato.</strong>
        </li>
        <li>(g) <strong>Risarcimento</strong>. Ti ricordiamo inoltre che chiunque subisca un danno materiale o
            immateriale causato da una
            violazione del Regolamento (UE) 2016/679, ha il diritto di ottenere il risarcimento del
            danno dal titolare del trattamento o dal responsabile del trattamento.
        </li>
        <li>(h) <strong>Diritto di proporre reclamo all’'autorità di controllo.</strong> Fatta salva la possibilità di
            rivolgersi al
            nostro Sci Club per l’esercizio dei tuoi diritti connessi ai trattamenti, tu puoi proporre reclamo dinnanzi
            all’autorità amministrativa indipendente competente nello Stato Membro dell’Unione Europea dove risiedi
            abitualmente, dove lavori, ovvero dove si è verificata un’asserita violazione della
            legge sulla protezione dei tuoi dati personali. Nel territorio italiano puoi presentare un reclamo all’
            Autorità di controllo
        </li>
    </ul>

    <p class="fs-6 mt-3" style="font-weight: 600;">11. Sicurezza dei dati</p>
    <p><span class="fs-6" style="font-weight: 600;">11.1</span> Applichiamo misure fisiche, tecniche e amministrative al
        fine di tutelare i dati personali sotto il nostro controllo da accesso, raccolta, utilizzo, divulgazione,
        riproduzione, modifica od eliminazione non autorizzati. Tutti i dati da te forniti sono memorizzati ed
        archiviati su server sicuri. </p>
    <p><span class="fs-6" style="font-weight: 600;">11.2</span> Se disponi di una password che consente di accedere ai
        nostri servizi o siti Web, è tua responsabilità mantenerla sicura e riservata. </p>

    <p class="fs-6" style="font-weight: 600;">12. Trasferimenti internazionali</p>
    <p><span class="fs-6" style="font-weight: 600;">12.1</span> Dal momento che Internet ѐ di per sé un ambito pubblico,
        il suo utilizzo per raccogliere e trattare
        dati personali implica necessariamente la trasmissione di dati a livello internazionale. Generalmente
        memorizziamo tutti i tuoi dati personali che raccogliamo attraverso il nostro sito web nel tuo Paese (ad esempio
        per i visitatori europei, nello Spazio Economico Europeo), tuttavia, è possibile che i
        tuoi dati personali siano trasmessi a Paesi terzi (dove le leggi sulla protezione dei dati personali
        possono essere diverse, ad esempio negli Stati Uniti).</p>
    <p><span class="fs-6" style="font-weight: 600;">12.2</span> Nel caso di trasferimento in Paesi terzi, abbiamo
        implementato misure di sicurezza e tutele appropriate per garantire che i tuoi dati siano adeguatamente protetti
        in tali Paesi terzi (ad esempio, utilizzando clausole contrattuali standard). Per avere maggiori informazioni
        sulle misure di sicurezza
        implementate, è possibile contattarci mediante le informazioni che ti abbiamo fornito alla sezione
        <strong>1</strong>.
    </p>

    <p class="fs-6" style="font-weight: 600;">13. Modifiche alla Policy</p>
    <p><span class="fs-6" style="font-weight: 600;">12.1</span> La presente Policy è stata aggiornata l'ultima volta in
        data 15/01/2020. Un avviso sarà pubblicato
        sulla nostra home page del sito web per 30 giorni ogni volta in cui la presente Policy viene modificata
        in modo sostanziale.
    </p>

    <p class="fs-6" style="font-weight: 600;">14. Domande riguardo la presente Policy</p>
    <p>
        In caso di domande, dubbi o reclami in merito alla presente Policy, alla gestione dei tuoi dati o all’esercizio
        dei tuoi diritti, non esitare a contattarci via e-mail all’indirizzo: <a
            href="mailto:info@sciclubbussoleno.com">info@sciclubbussoleno.com</a> la nostra
        politica generale sul trattamento dei dati personali al seguente link: <a
            href="https://shop.sciclubbussolenovalsusa.com" target="_blank">shop.sciclubbussolenovalsusa.com</a>
    </p>

    <h2 class="text-center mt-5" style="font-weight: bolder;">Definizioni:</h2>
    <p class="mt-3"><strong>«trattamento»:</strong> qualsiasi operazione o insieme di operazioni, compiute con o senza l'ausilio di
        processi
        automatizzati e applicate a dati personali o insiemi di dati personali, come la raccolta, la registrazione,
        l'organizzazione, la strutturazione, la conservazione, l'adattamento o la modifica, l'estrazione, la
        consultazione,
        l'uso, la comunicazione mediante trasmissione, diffusione o qualsiasi altra forma di messa a disposizione, il
        raffronto o l'interconnessione, la limitazione, la cancellazione o la distruzione (art. 4.2 GDPR);
    </p>
    <p>
        <strong>«dato personale»:</strong> qualsiasi informazione riguardante una persona fisica identificata o
        identificabile;
    </p>
    <p>
        <strong>«dati particolari»:</strong> dati personali che rivelino l’origine razziale o etnica, le opinioni
        politiche, le
        convinzioni
        religiose o filosofiche o l’appartenenza sindacale, nonché dati genetici, biometrici volti a identificare in
        modo
        univoco una persona fisica, dati relativi alla salute o alle abitudini sessuali o all’orientamento sessuale
        della
        persona (art. 9 GDPR);
    </p>
    <p>
        <strong>«dati giudiziari»:</strong> dati personali relativi alle condanne penali e ai reati (art. 10 GDPR);
    </p>
    <p>
        <strong>«interessato»:</strong> la persona fisica identificata o identificabile a cui si riferiscono i dati
        personali;
    </p>
    <p>
        <strong>«GDPR» o «Regolamento»:</strong> il Regolamento UE 2016/679 (General Data Protection Regulation) in
        materia di
        protezione dei dati persona;
    </p>
    <p>
        <strong>«rischio»:</strong> scenario descrittivo di un evento e delle relative conseguenze, che sono stimate in
        termini di
        gravità e probabilità per i diritti e le libertà.
        «limitazione di trattamento»: il contrassegno dei dati personali conservati con l'obiettivo di limitarne il
        trattamento in futuro;
    </p>
    <p>
        <strong>«profilazione»:</strong> qualsiasi forma di trattamento automatizzato di dati personali consistente
        nell'utilizzo di tali
        dati personali per valutare determinati aspetti personali relativi a una persona fisica, in particolare per
        analizzare o prevedere aspetti riguardanti il rendimento professionale, la situazione economica, la salute, le
        preferenze personali, gli interessi, l'affidabilità, il comportamento, l'ubicazione o gli spostamenti di detta
        persona fisica;
    </p>
    <p>
        <strong>«pseudonimizzazione»:</strong> il trattamento dei dati personali in modo tale che i dati personali non
        possano più
        essere attribuiti a un interessato specifico senza l'utilizzo di informazioni aggiuntive, a condizione che tali
        informazioni aggiuntive siano conservate separatamente e soggette a misure tecniche e organizzative intese a
        garantire che tali dati personali non siano attribuiti a una persona fisica identificata o identificabile;
    </p>
    <p>
        <strong>«archivio»:</strong> qualsiasi insieme strutturato di dati personali accessibili secondo criteri
        determinati,
        indipendentemente dal fatto che tale insieme sia centralizzato, decentralizzato o ripartito in modo funzionale o
        geografico;
    </p>
    <p>
        <strong>«titolare del trattamento»:</strong> la persona fisica o giuridica, l'autorità pubblica, il servizio o
        altro organismo
        che,
        singolarmente o insieme ad altri, determina le finalità e i mezzi del trattamento di dati personali; quando le
        finalità e i mezzi di tale trattamento sono determinati dal diritto dell'Unione o degli Stati membri, il
        titolare del
        trattamento o i criteri specifici applicabili alla sua designazione possono essere stabiliti dal diritto
        dell'Unione o
        degli Stati membri;
    </p>
    <p>
        <strong>«responsabile del trattamento»:</strong> la persona fisica o giuridica, l'autorità pubblica, il servizio
        o altro
        organismo
        che tratta dati personali per conto del titolare del trattamento;
    </p>
    <p>
        <strong>«destinatario»:</strong> la persona fisica o giuridica, l'autorità pubblica, il servizio o un altro
        organismo che riceve
        comunicazione di dati personali, che si tratti o meno di terzi. Tuttavia, le autorità pubbliche che possono
        ricevere comunicazione di dati personali nell'ambito di una specifica indagine conformemente al diritto
        dell'Unione o degli Stati membri non sono considerate destinatari; il trattamento di tali dati da parte di dette
        autorità pubbliche è conforme alle norme applicabili in materia di protezione dei dati secondo le finalità del
        trattamento
    </p>
    <p>
        <strong> «terzo»:</strong> la persona fisica o giuridica, l'autorità pubblica, il servizio o altro organismo che
        non sia
        l'interessato,
        il titolare del trattamento, il responsabile del trattamento e le persone autorizzate al trattamento dei dati
        personali sotto l'autorità diretta del titolare o del responsabile;
    </p>
    <p>
        <strong>«consenso dell'interessato»:</strong> qualsiasi manifestazione di volontà libera, specifica, informata e
        inequivocabile
        dell'interessato, con la quale lo stesso manifesta il proprio assenso, mediante dichiarazione o azione positiva
        inequivocabile, che i dati personali che lo riguardano siano oggetto di trattamento;
    </p>
    <p>
        <strong>«violazione dei dati personali»:</strong> la violazione di sicurezza che comporta accidentalmente o in
        modo illecito la
        distruzione, la perdita, la modifica, la divulgazione non autorizzata o l'accesso ai dati personali trasmessi,
        conservati o comunque trattati;
    </p>
    <p>
        <strong>«autorità di controllo»:</strong> l'autorità pubblica indipendente istituita da uno Stato membro;
        «organizzazione internazionale»: un'organizzazione e gli organismi di diritto internazionale pubblico a essa
        subordinati o qualsiasi altro organismo istituito da o sulla base di un accordo tra due o più Stati.
    </p>
    <p>
        <strong>«FORM»:</strong> In informatica, è un termine utilizzato per riferirsi all'interfaccia di
        un'applicazione che consente
        all'utente client
        di inserire e inviare al web server uno o più dati liberamente digitati dallo stesso; per descriverlo può essere
        utile la
        metafora della "scheda da compilare" per l'inserimento di dati.
    </p>
    <p><strong>«Clickstream URL»:</strong> La clickstream è l'analisi del flusso di navigazione degli utenti. Permette
        di monitorare le modalità con le quali gli utenti navigano, i processi d'acquisto, la reazione ai messaggi
        pubblicitari, ecc.
    </p>

</div>
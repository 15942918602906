<div class="gruppo-container container">
  <div class="row px-3">
    <div class="col-12 col-md-6">
      <h2 class="mb-3">Gestione gruppi</h2>
    </div>
    <div class="col-12 col-md-6">
      <div class="col">
        <a class="text-end d-none d-md-block text-muted" data-bs-toggle="modal" data-bs-target="#tutorialGruppi">Come
          funzionano i gruppi?</a>
        <a class="d-md-none d-block text-muted" data-bs-toggle="modal" data-bs-target="#tutorialGruppi">Come funzionano
          i gruppi?</a>
      </div>
      <app-tutorial-gruppi></app-tutorial-gruppi>
    </div>
  </div>
  <div class="row px-3">
    <div class="col-12 col-md-10">
      <p>Qui appaiono i gruppi che hai creato e quelli di cui fai parte.<br />Se sei il capogruppo <span style="display: inline-flex; align-items: center;" nz-icon nzType="crown" nzTheme="outline"></span>, cliccando sull'icona del carrello potrai effettuare un acquisto di skipass e servizi sia per te che per i partecipanti del tuo gruppo.</p>
      <p>Vuoi aggiungere un minorenne al tuo gruppo? Segui questo tutorial per capire come fare (<a href="../../assets/pdf/tutorial.pdf" target="_blank">clicca qui</a>).</p>
    </div>
    <div class="col d-flex flex-column align-items-end">
      <button class="mt-auto mb-3" nz-button routerLink="/gruppi/crea" nzType="primary">Crea gruppo</button>
    </div>
  </div>
  <div class="row mb-3 px-3" *ngIf="gruppi.length > 0">
    <div class="col mt-3 mb-3">
      <input nz-input type="text" placeholder="Cerca un gruppo..." [(ngModel)]="ricerca_gruppi">
    </div>
  </div>
  <p *ngIf="gruppi.length < 1" class="text-center my-5 text-muted">Non sei associato a nessun gruppo...</p>
  <div class="row">
    <div class="col">
      <nz-table nzBordered class="px-3" #gruppiTable *ngIf="gruppi.length > 0" [nzData]="gruppi | searchGruppi: ricerca_gruppi"
        nzShowPagination [nzTotal]="(gruppi | searchGruppi: ricerca_gruppi).length" [nzPageSize]="5"
        [nzPageSizeOptions]="[5, 10, 20, 30, 50, 100]" nzShowSizeChanger>
        <thead>
          <tr>
            <th [nzSortFn]="onOrdinamentoNome">Nome</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let gruppo of gruppiTable.data | searchGruppi: ricerca_gruppi">
            <td>
              <span>{{ gruppo.nome }} <i *ngIf="gruppo.id_cliente == user!.id"
                  style="display: inline-flex; align-items: center;" class="ms-2 fs-6" nz-icon nzType="crown"
                  nzTheme="outline" nzTooltipTitle="Sei proprietario di questo gruppo" nzTooltipPlacement="top"
                  nz-tooltip></i></span>
            </td>
            <td>
              <span class="text-end float-end azioni">
                <a href="#" [routerLink]="'/gruppi/' + gruppo.id" *ngIf="gruppo.id_cliente == user!.id" nzTooltipTitle="Modifica i dati del gruppo e invita altri membri!" nzTooltipPlacement="top" nz-tooltip>
                  <span class="fs-5" nz-icon nzType="edit" nzTheme="outline"></span>
                  <span class="d-none d-md-inline mx-1">Modifica</span>
                </a>
                <a href="#" [routerLink]="'/gruppi/' + gruppo.id" *ngIf="gruppo.id_cliente != user!.id" nzTooltipTitle="Visualizza i dati del gruppo" nzTooltipPlacement="top" nz-tooltip>
                  <span class="fs-5" nz-icon nzType="info-circle" nzTheme="outline"></span>
                  <span class="d-none d-md-inline mx-1">Visualizza</span>
                </a>
                <a href="#" [routerLink]="'/gruppi/' + gruppo.id + '/acquisto'" class="ms-3" *ngIf="gruppo.id_cliente == user!.id" nzTooltipTitle="Acquista per te e i membri del gruppo!" nzTooltipPlacement="top" nz-tooltip>
                  <span class="fs-5" nz-icon nzType="shopping-cart" nzTheme="outline"></span>
                  <span class="d-none d-md-inline mx-1">Acquista</span>
                </a>
                <a nz-popconfirm nzPopconfirmTitle="Sei sicuro di voler eliminare il gruppo? Tutti i membri verranno rimossi dal gruppo." class="ms-3" (nzOnConfirm)="deleteGruppo(gruppo.id)" *ngIf="gruppo.id_cliente == user!.id" nzTooltipTitle="Elimina il gruppo e tutti i suoi membri!" nzTooltipPlacement="top" nz-tooltip>
                  <span class="fs-5" nz-icon nzType="delete" nzTheme="outline"></span>
                  <span class="d-none d-md-inline mx-1">Elimina</span>
                </a>
              </span>
            </td>
          </tr>
        </tbody>
      </nz-table>
    </div>
  </div>
</div>
<div class="pb-4">
  <app-inviti></app-inviti>
</div>
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService } from '../services/api.service';
import { CartService } from '../services/cart.service';
import { TokenStorageService } from '../services/token-storage.service';
import { AuthUser, Prodotto, Utente } from '../shared/interface.model';
import { NzNotificationService } from 'ng-zorro-antd/notification';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {

  burgerOpen: boolean = false;
  propic: string = '';
  url: string = '';
  carrello?: { prodotti: Prodotto[], totale: number };
  user?: AuthUser;

  accountAperto: boolean = false;

  constructor(private _router: Router, private _token: TokenStorageService, private _api: ApiService, public _cart: CartService, private _notification: NzNotificationService) {
    this._cart.subjectCarrello.subscribe((cartData) => {
      this.carrello = cartData;
    });

    this._token.subjectUser.subscribe((user) => {      
      this.user = user == undefined ? this._token.getUser() : user;
      
      if (this.user?.id) {          
        this._api.getUtente(this.user.id).subscribe((utente: Utente) => {
          this.propic = utente.immagine_file.length > 0 ? utente.immagine_file : '../../assets/img/avatar.jpg';
          this.url = 'profilo';
        }, err => {
          this._notification.create('error', 'Errore', err, { nzPlacement: 'bottomLeft', nzDuration: 5000 });      
        });
      }      
    })
  }  

  ngOnInit(): void {
    this.user = this._token.getUser();    
    
    if (this.user?.id) {      
      this._api.getUtente(this.user!.id).subscribe((utente: Utente) => {
        this.propic = utente.immagine_file.length > 0 ? utente.immagine_file : '../../assets/img/avatar.jpg';
        this.url = 'profilo';
      }, err => {
        this._notification.create('error', 'Errore', err, { nzPlacement: 'bottomLeft', nzDuration: 5000 });      
      });
    } else {
      this.propic = '../../assets/img/avatar.jpg';
    }
  }

  toggleProdotti() {
    this._router.navigate(['/']).then(() => {
      setTimeout(() => {
        const element = document.getElementById("prodotti");

        if (element) {
          element.scrollIntoView({ behavior: 'smooth' });
        }
      }, 50);
      
    })    
  }

  toggleAccount(){
    this.accountAperto = !this.accountAperto;
  }

  toggleBurger(){
    this.burgerOpen = !this.burgerOpen;
  }

  closeBurger() {
    this.burgerOpen = false;
  }

  closeAccount() {
    this.accountAperto = false;
  }

  logout() {    
    this._token.clearStorage();    
    this._cart.clearCart();
    this._cart.clearCartGruppo();
    this.accountAperto = false;            
    this._router.navigate(['/'])
    // window.location.reload();
  }

}

import { DatePipe, Location } from '@angular/common';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { ApiService } from 'src/app/services/api.service';
import { TokenStorageService } from 'src/app/services/token-storage.service';
import { TokenGruppo } from 'src/app/shared/interface.model';

@Component({
  selector: 'app-registrazione-minorenne',
  templateUrl: './registrazione-minorenne.component.html',
  styleUrls: ['./registrazione-minorenne.component.scss']
})
export class RegistrazioneMinorenneComponent implements OnInit {

  public disabledDate = (current: Date): boolean => current > new Date();

  @ViewChild('form', { static: false }) form!: ElementRef;

  formRegistrazione!: FormGroup;
  errorMessage: string = '';
  loading: boolean = false;

  passwordVisible: boolean = false;
  passwordConfirmVisible: boolean = false;

  token_membro: string = '';
  data_token!: TokenGruppo;
  membro: boolean = false;

  constructor(private _api: ApiService, private _notification: NzNotificationService, private _token: TokenStorageService, private _router: Router, private _datePipe: DatePipe, private _location: Location) { }

  ngOnInit(): void {

    let body = document.getElementsByTagName('body')[0];
    body.classList.add('noscroll')

    this.formRegistrazione = new FormGroup({
      nome: new FormControl(null, Validators.required),
      cognome: new FormControl(null, Validators.required),
      codice_fiscale: new FormControl(null, Validators.required),
      data_nascita: new FormControl(null, Validators.required),
      comune_nascita: new FormControl(null, Validators.required),
      provincia_nascita: new FormControl(null, Validators.required),
      nazione_nascita: new FormControl(null, Validators.required),                  
      indirizzo: new FormControl(null, Validators.required),            
      privacy: new FormControl(null,[Validators.required, Validators.requiredTrue]),
      tutela: new FormControl(null, [Validators.required, Validators.requiredTrue])
    })

    this.token_membro = this._token.getMembro();
    this._api.chekTokenGruppo(this.token_membro).subscribe(data => {
      if (data.id_gruppo) {
        this.membro = true;
        this.data_token = data;
        this.formRegistrazione.patchValue({
          nome: data.nome,
          cognome: data.cognome
        })
      } else {
        this.enableScroll();
        this._router.navigate(['/']);
      }
    }, err => {
      this._router.navigate(['/']);
    })

  }

  enableScroll() {
    let body = document.getElementsByTagName('body')[0];
    body.classList.remove('noscroll')
  }

  onGoBack() {
    this.enableScroll();
    this._location.back();
  }

  onClose() {
    this.enableScroll();
    this._token.deleteMembro();
  }

  onSubmit(): void {
    this.errorMessage = '';
    this.loading = true;

    let cliente = {
      nome: this.formRegistrazione.get('nome')?.value,
      cognome: this.formRegistrazione.get('cognome')?.value,      
      codice_fiscale: this.formRegistrazione.get('codice_fiscale')?.value,
      data_nascita: this._datePipe.transform(this.formRegistrazione.get('data_nascita')?.value, 'yyyy-MM-dd'),
      comune_nascita: this.formRegistrazione.get('comune_nascita')?.value,
      provincia_nascita: this.formRegistrazione.get('provincia_nascita')?.value,
      nazione_nascita: this.formRegistrazione.get('nazione_nascita')?.value,
      indirizzo: this.formRegistrazione.get('indirizzo')?.value,
      privacy: this.formRegistrazione.get('privacy')?.value || false,
      parentela: this.formRegistrazione.get('parentela')?.value || false
    }

    // Registrazione di un minorenne
    this._api.registrazioneMinorenne(cliente).subscribe((res) => {

      this.loading = false;

      // Aggiungo il nuovo cliente registrato al gruppo      
      this._api.addMembroGruppo(this.data_token.id_gruppo, +this.data_token.id_utente, res.id).subscribe(data => {        

        this._token.deleteMembro();
        this._notification.create('success', 'Benvenuto nel gruppo ' + this.data_token.nome_gruppo, "Per poter acquistare prodotti e associarli alla tua utenza, chiedi al tuo capogrugruppo di acquistarli per te!", { nzPlacement: 'bottomLeft', nzDuration: 10000 });        
        this._router.navigate(['/login']);

      }, err => {
        this._notification.create('error', 'Errore', err, { nzPlacement: 'bottomLeft', nzDuration: 10000 });
      })
    }, (err) => {
      this.errorMessage = err;
      this.loading = false;
      this.form.nativeElement.scrollTop = 0;
    })
  }

}

import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-annullo-pagamento',
  templateUrl: './annullo-pagamento.component.html',
  styleUrls: ['./annullo-pagamento.component.scss']
})
export class AnnulloPagamentoComponent implements OnInit {

  constructor() { }        
  
  caricamento: boolean = false;  

  ngOnInit(): void {   
    
  }

}

import { Component, OnInit } from '@angular/core';
import { CartService } from '../services/cart.service';
import { AuthUser, Carrello } from '../shared/interface.model';
import { TokenStorageService } from '../services/token-storage.service';
import { RedirectService } from '../shared/redirect.service';

@Component({
  selector: 'app-offcanvas-cart',
  templateUrl: './offcanvas-cart.component.html',
  styleUrls: ['./offcanvas-cart.component.scss']
})
export class OffcanvasCartComponent implements OnInit {

  carrello!: Carrello;  

  user?: AuthUser;

  constructor(public _redirect: RedirectService, private _cart: CartService, private _token: TokenStorageService) { }

  ngOnInit(): void {    
    this._cart.subjectCarrello.subscribe((cartData) => {
      this.carrello = cartData;
      
      this._token.subjectUser.subscribe((user) => {      
        this.user = user == undefined ? this._token.getUser() : user;

        this._cart.carrello = this.carrello;
        sessionStorage.setItem('carrello', JSON.stringify(this.carrello));              
      })
    });
  }

  removeCartItem(id: number): void {
    this._cart.removeProduct(id);
  }

}

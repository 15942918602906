import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-esito-pagamento-dopo',
  templateUrl: './esito-pagamento-dopo.component.html',
  styleUrls: ['./esito-pagamento-dopo.component.scss']
})
export class EsitoPagamentoDopoComponent implements OnInit {

  constructor(private _route: ActivatedRoute) { }

  caricamento: boolean = false;  
  ordine: boolean = false;

  ngOnInit(): void {

    this._route.queryParams.subscribe(params => {

      let ordine = params['ordine'];      
      this.caricamento = true;

      setTimeout(() => {
        if (ordine) {
          this.ordine = true;
          this.caricamento = false
        } else 
          this.caricamento = false;
      }, 500);
    })

  }


}

<div class="container">
    <div class="row px-3">
        <div class="col">
            <h2 class="mb-3">Ordini in sospeso</h2>
        </div>
    </div>
    <div class="row px-3">
        <div class="col">
            <p>Seleziona i prodotti che desideri finalizzare e procedi al pagamento per completare la tua esperienza di shopping senza problemi!</p>
        </div>
    </div>
    <div class="row px-3">
        <div class="col text-end">
            <button nz-button nzType="primary" nzSize="large" type="button" [disabled]="ordini_selezionati.size <= 0" (click)="onPagamento()">Paga i prodotti selezionati</button>
        </div>
    </div>
    <div class="row mb-3 px-3" *ngIf="ordini.length > 0">
        <div class="col mt-3 mb-3">
            <input nz-input type="text" placeholder="Cerca un prodotto..." [(ngModel)]="ricerca_ordini">
        </div>
    </div>
    <p *ngIf="ordini.length < 1" class="text-center my-5 text-muted">Non hai nessun ordine da pagare...</p>
    <div class="row">
        <div class="col">
            <nz-table nzBordered class="px-3" #ordiniTable [nzData]="ordini | searchOrdini: ricerca_ordini" *ngIf="ordini.length > 0" nzShowPagination [nzTotal]="(ordini | searchOrdini: ricerca_ordini).length" [nzPageSize]="5" [nzPageSizeOptions]="[5, 10, 25, 50]" nzShowSizeChanger>
                <thead>
                    <tr>
                        <th class="text-center">Seleziona</th>
                        <th [nzSortFn]="onOrdinamentoOrdine">N° ordine</th>
                        <th [nzSortFn]="onOrdinamentoProdotti" class="text-start">Prodotto</th>
                        <th [nzSortFn]="onOrdinamentoMembro" class="text-start">Membro</th>
                        <th [nzSortFn]="onOrdinamentoTotale" class="text-center">Prezzo</th>
                        <th [nzSortFn]="onOrdinamentoDataAcquisto" class="text-end">Data di acquisto</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let ordine of ordiniTable.data | searchOrdini: ricerca_ordini">
                        <td *ngIf="ordine.metodo != 'Bonifico'" [nzChecked]="ordine.selezionato || false" (nzCheckedChange)="onItemChecked(ordine, $event)"></td>
                        <td *ngIf="ordine.metodo == 'Bonifico'" class="text-center"><a (click)="mostraInfoBonifici = true; idOrdineEsempio = ordine.id_ordine">In attesa del bonifico <!-- <i class="bi bi-info-circle"></i> --></a></td>
                        <td>#{{ordine.id_ordine}} <i *ngIf="ordine.id_gruppo" [routerLink]="['/gruppi',ordine.id_gruppo]" class="cursor-pointer ms-3 fs-6 bi bi-people-fill" [nzTooltipTitle]="ordine.gruppo" nzTooltipPlacement="top" nz-tooltip></i></td>
                        <td class="text-start">{{ordine.prodotto}}</td>
                        <td [ngStyle]="{'text-align': ordine.id_gruppo ? 'left' : 'center'}">{{ordine.id_gruppo ? ordine.cliente : '-'}}</td>
                        <td class="text-center">{{ordine.prezzo | currency: 'EUR'}}</td>
                        <td class="text-end">{{ordine.data_creazione | date: 'dd/MM/yyyy HH:mm' }}</td>
                    </tr>
                </tbody>
            </nz-table>
        </div>
    </div>
</div>

<nz-modal [(nzVisible)]="mostraInfoTesseramenti" nzCentered nzTitle="Pagamento Tesseramento" [nzFooter]="null" (nzOnCancel)="mostraInfoTesseramenti = false" (nzOnOk)="mostraInfoTesseramenti =false">
    <ng-container *nzModalContent>
        <p>Per poter procedere con l'acquisto di altri prodotti dello SciClub Bussoleno, <strong>completa il pagamento dell'ordine contenente il Tesseramento</strong></p>        
        <p>Dalla sezione <strong>Ordini in sospeso</strong>, seleziona la spunta sulla riga del tesseramento e premi sul pulsante <strong>Paga i prodotti selezionati</strong></p>
    </ng-container>
</nz-modal>

<nz-modal [(nzVisible)]="mostraInfoBonifici" nzCentered nzTitle="Pagamento tramite bonifico" [nzFooter]="null" (nzOnCancel)="mostraInfoBonifici = false" (nzOnOk)="mostraInfoBonifici = false">
    <ng-container *nzModalContent>
        <p>All'acquisto hai selezionato il pagamento tramite bonifico, perciò questo prodotto resterà inattivo finchè il pagamento non verrà finalizzato.
        <strong>Questo può richiedere fino due giorni lavorativi.</strong>
        </p>
        <p>Per completare il pagamento, effettua un bonifico a queste coordinate bancarie:</p>
        <ul>
            <li><strong>IBAN:</strong> IT79H0326830180052740239860</li>
            <li><strong>Nome intestatario:</strong> Sci Club Bussoleno ASD</li>
            <li><strong>Causale:</strong> Saldo ordine numero #{{idOrdineEsempio}}</li>
        </ul>
        <p class="mt-3">In caso di problemi, contatta l'assistenza a <a href="mailto:supporto@sciclubbussolenovalsusa.com">supporto@sciclubbussolenovalsusa.com</a></p>
    </ng-container>
</nz-modal>
import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService } from 'src/app/services/api.service';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit {
  
  error = '';
  loading: boolean = false;
  passwordVisible: boolean = false;
  passwordConfirmVisible: boolean = false;

  token!: string;
  id!: number;

  formReset!: FormGroup;

  constructor(private _api: ApiService, private _router: Router, private _route: ActivatedRoute, private _location: Location) {}  

  ngOnInit(): void {    

    this.formReset = new FormGroup({
      password: new FormControl(null, Validators.required),
      confirm_password: new FormControl(null, Validators.required)
    })

    this._route.queryParams.subscribe(params => {                                
      this.token = params['token'];
      if (this.token) { 
        this._api.checkTokenReset(this.token).subscribe(data => {               
          if (!data.id) {
            this._router.navigate(['/richiesta-password']);
          } else {
            this.id = data.id;
          }
        }, err => {
          this._router.navigate(['/richiesta-password']);
        })
      } else {
        this._router.navigate(['/richiesta-password']);
      }
    })    
  }

  enableScroll() {
    let body = document.getElementsByTagName('body')[0];
    body.classList.remove('noscroll')
  }

  onGoBack() {
    this.enableScroll();
    this._location.back();
  }

  onSubmit(): void {
    this.loading = true;
    this.error = '';  
    
    let reset = {
      password: this.formReset.get('password')?.value,
      confirm_password: this.formReset.get('confirm_password')?.value
    }

    if (reset.password !== reset.confirm_password) {
      this.error = 'Le password devono coincidere';
      this.loading = false;
    } else {
      this.loading = true;
      this._api.reset(reset.password, this.id).subscribe((res) => {
        this.loading = false;        
        this._router.navigate(['/login']);
      }, (err) => {
        this.error = err;
        this.loading = false;
      })
    }
  }
}

<ng-template #indicatorTemplate>
  <img src="../../../assets/img/riding-skiing.gif" alt="" width="250px">
</ng-template>
<nz-spin [nzSpinning]="caricamento" [nzIndicator]="indicatorTemplate"  class="spinning spinner" >
  <div class="border container gruppo-container p-0">
    <!-- <div class="row px-4 py-2">
      <div class="col">
        <i class="ps-2 bi bi-arrow-left fs-3 cursor-pointer" (click)="onGoBack()"></i>
      </div>
    </div> -->
    <div class="row p-lg-4 p-1 py-3 m-0">
      <div class="col">
        <h4 class="mb-2">Compra per il gruppo: {{gruppo?.nome}}</h4>
        <p class="pb-0 mb-0">Con un solo click potrai acquistare ski pass e servizi e prodotti per TUTTI i partecipanti
          del tuo gruppo.<br />
          Nella colonna a sinistra puoi selezionare il membro, a destra i prodotti che vuoi acquistare per lui.</p>
      </div>
    </div>
    <div class="row border-top m-0 p-0">
      <div class="col-lg-4 border-end m-0 p-0">
        <h5 class="mb-3 px-3 fw-normal p-5 pb-2">
          Seleziona un membro del gruppo
        </h5>
        <ul class="list-group list-group-flush">
          <li class="list-group-item cursor-pointer" *ngFor="let membro of membri" (click)="selezMembro(membro); listProdotti.scrollTop = 0" [class.selezionato]="membro.id == membroSelezionato?.id">
            <div class="row">
              <div class="col">
                <span>
                  {{membro?.nome}} {{membro?.cognome}}
                  <i *ngIf="gruppo?.id_cliente == membro?.id" style="transform: translateY(-25%);" class="fs-6" nz-icon nzType="crown" nzTheme="outline"></i>              
                  <span class="badge rounded-pill bg-light border text-black float-end" style="transform: translateY(-10%)">{{contaProdotti[membro.id] ? contaProdotti[membro.id] + (contaProdotti[membro.id] == 1 ? ' prodotto' : ' prodotti') : 'Aggiungi un prodotto'}} </span>
                  <!-- <button *ngIf="membro.id != membroSelezionato?.id" class="d-inline btn btn-sm btn-primary float-end rounded-0">
                    Seleziona
                  </button> -->
                </span>
              </div>
            </div>   
            <div class="row">
              <div class="col">
                <form [formGroup]="infoUtente" id="infoUtente" *ngIf="membroSelezionato && membro.id == membroSelezionato.id">
                  <!-- obsoleto [style.display]="membro.id == membroSelezionato?.id ? 'block' : 'none'" -->
                  <div class="row mb-3 mt-3">
                    <div class="col">
                      <div [ngStyle]="{'background-image':'url('+ img_profilo +')'}" class="rounded-circle border"
                        style="background-size:cover; height: 104px; aspect-ratio: 1/1; margin: 0px auto;"></div>
                    </div>
                  </div>
                  <div class="row mb-1 mt-3">
                    <div class="col-md-6"><input formControlName="nome" nz-input placeholder="Nome" /></div>
                    <div class="col-md-6 mt-md-0 mt-2"><input formControlName="cognome" nz-input placeholder="Cognome" /></div>
                  </div>                         
                  <div class="row mb-1">
                    <div class="col">
                      <nz-date-picker [nzDisabledDate]="disabledDate" formControlName="data_nascita" nzFormat="dd/MM/yyyy" class="w-100 data_membro">
                      </nz-date-picker>
                    </div>
                  </div>
                  <div class="row mb-1">
                    <div class="col"><input formControlName="codice_fiscale" nz-input placeholder="Codice Fiscale"/></div>
                  </div>
                </form>
              </div>
            </div>                     
          </li>
        </ul>
      </div>
      <div class="col-lg-8 p-lg-5 p-3 my-lg-0 my-5">
        <div *ngIf="!membroSelezionato" class="gap-2" style="width: 100%; min-height: 300px; display: flex; align-items: center; justify-content: center; flex-direction: column;" >
          <h1 class="text-muted"><i class="bi bi-patch-question-fill"></i></h1>
          <h5 class="text-muted text-center">Scegli per quale membro del gruppo selezionare i prodotti</h5>
        </div>
        <div id="selezioneProdotti" [style.display]=" membroSelezionato ? 'block' : 'none'">
          <h5 class="mb-3">
            <span class="fw-normal">Prodotti acquistabili per</span> {{membroSelezionato?.nome}} {{membroSelezionato?.cognome}}
          </h5>
          <div class="row">
            <div class="col-lg-4 mb-1">
              <input nz-input type="text" placeholder="Cerca un prodotto..." [(ngModel)]="ricerca">
            </div>
            <div class="col-lg-4 mb-1">
              <nz-select class="w-100" nzPlaceHolder="Filtra per categoria" nzMode="multiple" nzAllowClear nzShowSearch [(ngModel)]="cat">
                <nz-option [nzValue]="''" [nzLabel]="'Mostra tutte le categorie'"></nz-option>
                <nz-option *ngFor="let categoria of categorie" [nzValue]="categoria.id" [nzLabel]="categoria.nome">
                </nz-option>
              </nz-select>
            </div>
            <div class="col-lg-4 mb-1">
              <button class="mx-auto w-100" nz-button (click)="toggleSoloAttivi()" [nzType]="soloAttivi ? 'dashed' : 'primary'"> {{soloAttivi ? 'Tutti i prodotti' : 'Solo disponibili'}}
              </button>
            </div>
          </div>
          <div class="row mt-2">
            <div class="col">
              <div class="row mt-5 text-center" *ngIf="(prodotti | search: ricerca | searchCategoria: cat | onlyActive: soloAttivi ).length == 0">
                <span class="fs-6">Nessun risultato per {{ricerca.length > 0 ? '"' + ricerca + '"' : 'questa categoria'}}</span>
              </div>
              <ul style="overflow-y: scroll; max-height: 400px; scroll-behavior: smooth;" class="list-group-flush mt-3" #listProdotti>
                <div class="prodotto" *ngFor="let prodotto of prodotti | search: ricerca | searchCategoria: cat | onlyActive: soloAttivi">
                  <li class="list-group-item border-bottom p-2 d-flex justify-content-between align-items-center bg-gray" *ngIf="prodotto.disabilitato || (disable_all && !prodotto.obbligatorio)"> 
                    {{prodotto.nome}} {{prodotto.stato}} - {{prodotto.prezzo | currency : 'EUR'}}
                    <span style="min-width: 60px; text-align: right; margin-left: 15px;">
                      <i class="bi bi-info-circle-fill fs-5 me-3"></i>
                      <i class="bi fs-4 fw-bolder bg-gray bi-cart-x-fill" nz-tooltip [nzTooltipTitle]="(disable_all && !prodotto.obbligatorio) ? 'Aggiungi prima al carrello il tesseramento dello Sci Club'  : (prodotto.attivo ? 'Prodotto già acquistato e attivo!' : 'Prodotto non disponibile')"></i>
                    </span>
                  </li>
                  <li class="list-group-item border-bottom p-2 d-flex justify-content-between align-items-center" [ngClass]="prodotto.selezionato ? 'bg-primary text-white' : ''" *ngIf="!prodotto.disabilitato && !(disable_all && !prodotto.obbligatorio)"> 
                    {{prodotto.nome}} {{prodotto.stato}} - {{prodotto.prezzo | currency : 'EUR'}}
                    <span style="min-width: 60px; text-align: right; margin-left: 15px;">
                      <i class="bi bi-info-circle-fill fs-5 me-3 cursor-pointer" data-bs-toggle="modal" data-bs-target="#infoProdotto" (click)="setProdottoInfo(prodotto)"></i>
                      <i class="bi fs-4 fw-bolder cursor-pointer" [ngClass]="{'bi-cart-plus-fill': !prodotto.selezionato, 'bi-cart-dash-fill': prodotto.selezionato}" (click)="aggiungiProdotto(prodotto)"></i>
                    </span>
                  </li>
                </div>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row border-top p-lg-3 p-1 py-3 m-0">      
      <div class="col d-flex align-items-center justify-content-end checkout-desktop gap-2">
        <h6 class="my-auto d-inline me-3"><span class="fw-normal">Totale: </span>{{totale | currency: 'EUR'}}</h6>
        <button nz-button nzType="default" nzSize="large" type="button" (click)="onGoBack()">Torna ai gruppi</button>
        <button nz-button nzType="primary" nzSize="large" type="button" [disabled]="totale <= 0" (click)="cassa()">Vai alla cassa</button>
      </div>
      <div class="col checkout-mobile">        
        <h6 class="my-auto d-inline me-3"><span class="fw-normal">Totale: </span>{{totale | currency: 'EUR'}}</h6>
        <button nz-button nzType="default" nzSize="large" type="button" class="mt-3" (click)="onGoBack()">Torna ai gruppi</button>
        <button nz-button nzType="primary" nzSize="large" type="button" [disabled]="totale <= 0" (click)="cassa()">Vai alla cassa</button>
      </div>
    </div>
  </div>
</nz-spin>

<!-- Info Prodotto -->
<div class="modal fade" id="infoProdotto" tabindex="-1" aria-labelledby="infoProdotto" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content rounded-0 border-0">
      <div class="modal-header">
        <h1 class="modal-title fs-5" id="infoProdotto">Informazioni sul prodotto</h1>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body">
        <div class="row">
          <div class="col-10 mx-auto">
            <div class="img-container mb-3">
              <img *ngIf="prodottoInfo?.immagine_file" [src]="prodottoInfo?.immagine_file" alt="">
              <span *ngIf="!prodottoInfo?.immagine_file" class="text-muted">Nessuna immagine disponibile</span>
            </div>
          </div>
        </div>
        <div class="row col-10 mx-auto">
          <div class="col-8">
            <h5 class="fw-bolder">{{prodottoInfo?.nome}}</h5>
            <p>{{prodottoInfo?.descrizione ? prodottoInfo?.descrizione : 'Nessuna descrizione'}}</p>
          </div>
          <div class="col text-end">
            <h5>
              {{prodottoInfo?.prezzo | currency: 'EUR'}}
            </h5>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button data-bs-dismiss="modal" [disabled]="prodottoInfo.disabilitato || (disable_all  && (!prodottoInfo.obbligatorio))" *ngIf="prodottoInfo" nz-button [nzType]="prodottoInfo.selezionato ? 'default' :'primary'" nzSize="large" (click)="aggiungiProdotto(prodottoInfo)">{{prodottoInfo.selezionato ? 'Rimuovi dal carrello' : 'Aggiungi al carrello'}}</button>
      </div>
    </div>
  </div>
</div>
import { Component, OnInit } from '@angular/core';
import { ApiService } from '../services/api.service';
import { TokenStorageService } from '../services/token-storage.service';
import { AuthUser, Gruppo } from '../shared/interface.model';
import { Router } from '@angular/router';
import { NzNotificationService } from 'ng-zorro-antd/notification';

@Component({
  selector: 'app-gruppi',
  templateUrl: './gruppi.component.html',
  styleUrls: ['./gruppi.component.scss']
})
export class GruppiComponent implements OnInit {

  gruppi: Gruppo[] = [];

  user?: AuthUser;  
  loading: boolean = false;

  ricerca_gruppi: string = ''
  
  constructor( private _api: ApiService, private _token: TokenStorageService, private _notification: NzNotificationService) { }

  ngOnInit(): void {    
    
    this.user = this._token.getUser();          
    this._api.gruppiChanged.subscribe((gruppi: Gruppo[]) => {
      this.gruppi = gruppi;
    }, err => {
      this._notification.create('error', 'Errore', err, { nzPlacement: 'bottomLeft', nzDuration: 5000 });      
    })
  }

  onOrdinamentoNome(a: Gruppo, b: Gruppo) {    
    return a.nome.localeCompare(b.nome)
  }

  deleteGruppo(id: number) {
    this._api.deleteGruppo(id).subscribe(data => {
      this._api.setGruppi(this.user!.id);          
      this.loading = false;
    }, err => {
      this._notification.create('error', 'Errore', err, { nzPlacement: 'bottomLeft', nzDuration: 5000 });      
    })
  }  

}

import { Component, OnInit } from '@angular/core';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { ApiService } from 'src/app/services/api.service';

@Component({
  selector: 'app-informativa-assicurazione',
  templateUrl: './informativa-assicurazione.component.html',
  styleUrls: ['./informativa-assicurazione.component.scss']
})
export class InformativaAssicurazioneComponent implements OnInit {

  constructor(private _api: ApiService, private _notification: NzNotificationService) { }

  stagione: {inizio_stagione: number, fine_stagione: number} = {inizio_stagione: 0, fine_stagione: 0}

  ngOnInit(): void {

    this._api.getStagione().subscribe(stagione => {      
      this.stagione.inizio_stagione = new Date(stagione.inizio_stagione).getFullYear();
      this.stagione.fine_stagione = new Date(stagione.fine_stagione).getFullYear();      
    }, err => {
      this._notification.create('error', 'Errore', err, { nzPlacement: 'bottomLeft', nzDuration: 5000 });      
    })

  }

}

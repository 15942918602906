import { Component, OnInit } from '@angular/core';
import { ApiService } from '../../services/api.service';
import { ActivatedRoute, Router } from '@angular/router';
import { TokenGruppo } from '../../shared/interface.model';
import { TokenStorageService } from 'src/app/services/token-storage.service';
import { Location } from '@angular/common';

@Component({
  selector: 'app-membro',
  templateUrl: './membro.component.html',
  styleUrls: ['./membro.component.scss']
})
export class MembroComponent implements OnInit { 

  constructor(private _api: ApiService, private _router: Router, private _route: ActivatedRoute, private _token: TokenStorageService, private _location: Location) { }

  token!: string;
  dati?: TokenGruppo;

  ngOnInit(): void {

    let body = document.getElementsByTagName('body')[0];
    body.classList.add('noscroll')
    
    this._route.queryParams.subscribe(params => {                                
      this.token = params['token'];
      if (this.token) { 
        this._api.chekTokenGruppo(this.token).subscribe(data => {                         
          if (!data.id_gruppo) {
            this._router.navigate(['/']);
          } else {
            this.dati = data;
            this._token.setMembro(this.token);
          }
        }, err => {
          this._router.navigate(['/']);
        })
      } else {
        this._router.navigate(['/']);
      }
    })  
  }

  enableScroll() {
    let body = document.getElementsByTagName('body')[0];
    body.classList.remove('noscroll')
  }

  onClose() {
    this.enableScroll();
    this._token.deleteMembro();
  }

  onGoBack() {
    this.enableScroll();
    this._location.back();
  }

}

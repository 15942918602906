import { DatePipe, Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { NzUploadFile } from 'ng-zorro-antd/upload';
import { ApiService } from 'src/app/services/api.service';
import { TokenStorageService } from 'src/app/services/token-storage.service';
import { AuthUser, CodiceSconto, Gruppo, Membri, Ordine, Tesseramento, Utente } from 'src/app/shared/interface.model';
import * as bootstrap from 'bootstrap';

@Component({
  selector: 'app-gruppo',
  templateUrl: './gruppo.component.html',
  styleUrls: ['./gruppo.component.scss']
})
export class GruppoComponent implements OnInit {
  public disabledDate = (current: Date): boolean => current > new Date();

  gruppo?: Gruppo;
  membri: Membri[] = [];
  id_gruppo!: number;
  ordini: Ordine[] = [];
  tesseramenti: Tesseramento[] = [];
  codici_sconto: CodiceSconto[] = [];

  user?: AuthUser;

  alertMessage: string = '';
  alertType: "success" | "info" | "warning" | "error" = 'success';
  alertVisible: boolean = false;

  alertMessageMembro: string = '';
  alertTypeMembro: "success" | "info" | "warning" | "error" = 'success';
  alertVisibleMembro: boolean = false;

  buttonVisible: boolean = false;
  loading: boolean = false;
  loadingMembro: boolean = false;
  gruppo_default: boolean = false;

  modal: boolean = false;

  file_immagine!: string;
  immagine!: string;

  fileList: NzUploadFile[] = [];
  propic: string = '../../../assets/img/default-group.jpg';
  propicUtente: string = '../../assets/img/avatar.jpg';

  formGruppo!: FormGroup;
  formMembro!: FormGroup;
  formUtente!: FormGroup

  file_immagine_utente!: string;
  immagine_utente!: string;
  fileListUtente: NzUploadFile[] = [];

  membroSelezionato: Utente | Membri | undefined;

  ricerca_membri: string = '';
  ricerca_ordini_prodotti: string = '';
  ricerca_ordini_membri: string = '';
  ricerca_tesseramenti_prodotti: string = '';
  ricerca_tesseramenti_membri: string = '';
  ricerca_codici_sconto: string = '';

  modale: any;

  constructor(private _datePipe: DatePipe, private _api: ApiService, private _token: TokenStorageService, private _route: ActivatedRoute, private _router: Router, private _location: Location, private _notification: NzNotificationService) { }

  ngOnInit(): void {
    this.modale = new bootstrap.Modal(document.getElementById('modificaAnagrafica')!);

    this.formGruppo = new FormGroup({
      nome: new FormControl(null, Validators.required),
      descrizione: new FormControl(null)
    })

    this.formMembro = new FormGroup({
      cognome: new FormControl(null, Validators.required),
      nome: new FormControl(null, Validators.required),
      email: new FormControl(null, [Validators.required, Validators.email])
    })

    this.formUtente = new FormGroup({
      nome: new FormControl(null, Validators.required),
      cognome: new FormControl(null, Validators.required),
      data_nascita: new FormControl(null, Validators.required),
      comune_nascita: new FormControl(null, Validators.required),
      provincia_nascita: new FormControl(null, Validators.required),
      nazione_nascita: new FormControl(null, Validators.required),
      indirizzo: new FormControl(null, Validators.required),
      comune: new FormControl(null),
      provincia: new FormControl(null),
      nazione: new FormControl(null),
      codice_fiscale: new FormControl(null, Validators.required),
      telefono: new FormControl(null)
    })

    this.getData();
  }

  getData() {

    // Recupero l'utente
    this.user = this._token.getUser();

    // Recupero il parametro nell'url
    this._route.params.subscribe(params => {
      this.id_gruppo = params['id'];

      // Recupero i dati del grupo
      this._api.getGruppo(this.id_gruppo).subscribe((gruppo: Gruppo) => {
        this.gruppo = gruppo;
        this.propic = '../../../assets/img/default-group.jpg';
        this.propic = gruppo.immagine_file.length > 0 ? gruppo.immagine_file : this.propic;

        // Controllo se l'utente loggato è capogruppo e se è presente nel gruppo
        this._api.checkMembroGruppo(this.user!.id, +this.id_gruppo).subscribe(data => {

          if (this.user!.id == gruppo.id_cliente) {
            this.buttonVisible = true;
          } else {
            this.formGruppo.disable();
          }

          this.formGruppo.setValue({
            nome: gruppo.nome,
            descrizione: gruppo.descrizione
          })

          // Recupero i membri del gruppo
          this._api.getMembriGruppo(this.id_gruppo).subscribe((membri: Membri[]) => {
            this.membri = membri;

            // Ciclo per i membri
            for (let membro of this.membri) {

              // Calcolo l'età e cerco di capire se è maggiorenne
              let differenza = Math.abs(Date.now() - new Date(membro.data_nascita!).getTime());
              let eta = Math.floor((differenza / (1000 * 3600 * 24)) / 365.25);
              membro.over18 = eta >= 18;
            }

            // Recupero gli ordini del gruppo
            this._api.getOrdiniGruppo(this.user!.id, gruppo.id).subscribe(ordini => {
              this.ordini = ordini;

              this._api.getTesseramentiGruppo(gruppo.id).subscribe(tesseramenti => {
                this.tesseramenti = tesseramenti;

                this._api.getCodiciScontoGruppo(gruppo.id).subscribe(codici_sconto => {
                  this.codici_sconto = codici_sconto;

                }, err => {
                  this._router.navigate(['/gruppi'])
                })

              }, err => {
                this._router.navigate(['/gruppi'])
              })


            }, err => {
              this._router.navigate(['/gruppi'])
            })

          }, err => {
            this._router.navigate(['/gruppi'])
          })

        }, (err: any) => {
          this._router.navigate(['/gruppi'])
        })

      }, (err: any) => {
        this._router.navigate(['/gruppi'])
      })
    })
  }

  onOrdinamentoMembro(a: Membri, b: Membri) {
    return a.cognome.localeCompare(b.cognome)
  }

  onOrdinamentoEmail(a: Membri, b: Membri) {
    return a.email.localeCompare(b.email)
  }

  onOrdinamentoOrdine(a: Ordine, b: Ordine) {
    return a.id_ordine - b.id_ordine;
  }

  onOrdinamentoMembri(a: Ordine, b: Ordine) {
    return a.cliente.localeCompare(b.cliente)
  }

  onOrdinamentoProdotti(a: Ordine, b: Ordine) {
    return a.prodotto.localeCompare(b.prodotto)
  }

  onOrdinamentoTotale(a: Ordine, b: Ordine) {
    return a.prezzo - b.prezzo;
  }

  onOrdinamentoData(a: Ordine, b: Ordine) {
    return a.data_creazione.toString().localeCompare(b.data_creazione.toString())
  }

  onOrdinamentoProdotto(a: Tesseramento, b: Tesseramento) {
    return a.prodotto.localeCompare(b.prodotto)
  }

  onOrdinamentoCodice(a: Tesseramento, b: Tesseramento) {
    return (a.codice_tesseramento || '').localeCompare((b.codice_tesseramento || ''))
  }

  onOrdinamentoPrezzo(a: Tesseramento, b: Tesseramento) {
    return a.prezzo - b.prezzo;
  }

  onOrdinamentoDataScadenza(a: Tesseramento, b: Tesseramento) {
    return a.fine_validita.toString().localeCompare(b.fine_validita.toString())
  }

  onOrdinamentoCodiceSconto(a: CodiceSconto, b: CodiceSconto) {
    return a.codice.localeCompare(b.codice);
  }

  onOrdinamentoCodiceScontoMembro(a: CodiceSconto, b: CodiceSconto) {
    return (a.cognome || '').localeCompare(b.cognome || '');
  }

  onOrdinamentoCodiceScontoValore(a: CodiceSconto, b: CodiceSconto) {
    return a.valore - b.valore
  }

  deleteMembro(id: number) {
    this._api.deleteMembroGruppo(+this.id_gruppo, id).subscribe(data => {
      this.membri = this.membri.filter(d => d.id !== id);
      if (this.gruppo?.id_cliente != this.user?.id) {
        this._router.navigate(['/gruppi']);
      } else {
        this.onResult("Membro eliminato con successo", "success");
      }
    }, err => {
      this._notification.create('error', 'Errore', err, { nzPlacement: 'bottomLeft', nzDuration: 5000 });
    })
  }

  deleteGruppo(id: number) {
    this._api.deleteGruppo(id).subscribe(data => {
      this.onResult("Gruppo eliminato con successo", "success");
    }, (err: any) => {
      this._notification.create('error', 'Errore', err, { nzPlacement: 'bottomLeft', nzDuration: 5000 });
    })
  }

  deleteImmagine() {
    this._api.deleteImmagineGruppo(+this.id_gruppo).subscribe(data => {
      this.gruppo!.immagine_file = '';
      this.gruppo!.immagine = '';
    }, (err: any) => {
      this._notification.create('error', 'Errore', err, { nzPlacement: 'bottomLeft', nzDuration: 5000 });
    })
  }

  deleteImmagineUtente(id: number) {
    this._api.deleteImmagineUtente(id).subscribe(data => {
      this.immagine_utente = '';
      this.file_immagine_utente = '';
      this.membroSelezionato!.immagine = '';
      this.membroSelezionato!.immagine_file = '';
      this.propicUtente = '';
    }, (err: any) => {
      this._notification.create('error', 'Errore', err, { nzPlacement: 'bottomLeft', nzDuration: 5000 });
    })
  }

  onGoBack() {
    this._location.back();
  }

  onMembro(membro: Membri) {
    this._api.getUtente(membro.id).subscribe(data => {
      this.membroSelezionato = data;
      this.formUtente.patchValue(this.membroSelezionato);
      this.immagine_utente = data.immagine;
      this.file_immagine_utente = data.immagine_file;

      this.propicUtente = this.membroSelezionato.immagine_file.length > 0 ? this.membroSelezionato.immagine_file : this.propic;
      this.modal = true;
    }, err => {
      this._notification.create('error', 'Errore', err, { nzPlacement: 'bottomLeft', nzDuration: 5000 });
    })
  }

  addMembro() {
    let membro = {
      email: this.formMembro.get('email')?.value,
      cognome: this.formMembro.get('cognome')?.value,
      nome: this.formMembro.get('nome')?.value,
      id_utente: this.user!.id
    }

    this.loadingMembro = true;

    this._api.addRichiestaMembroGruppo(+this.id_gruppo, membro).subscribe(data => {
      this.formMembro.patchValue({
        nome: '',
        cognome: '',
        email: ''
      })
      this.onResult("Un'email contenente l'invito è stata inviata all'indirizzo email inserito!", "success");
    }, err => {
      this.onResult(err, 'error');
    })
  }

  onUploadGruppo = (file: any): boolean => {
    this.fileList = this.fileList.concat(file);

    const isJpgOrPng = this.fileList.length > 0 ? this.fileList[0]!.type === 'image/jpeg' || this.fileList[0]!.type === 'image/png' : true;

    if (!isJpgOrPng) {
      this.onResult("L'immagine del gruppo deve essere di tipo JPG, PNG o JPEG", "error");
      return false;
    } else {
      const reader = new FileReader();
      reader.onload = () => {
        this.immagine = file.name;
        this.file_immagine = reader.result as string;
      }
      reader.readAsDataURL(file);

      return false;
    }
  };

  onUploadMinorenne = (file: any): boolean => {
    this.fileListUtente = this.fileListUtente.concat(file);

    const isJpgOrPng = this.fileListUtente.length > 0 ? this.fileListUtente[0]!.type === 'image/jpeg' || this.fileListUtente[0]!.type === 'image/png' : true;

    if (!isJpgOrPng) {
      this.onResult("L'immagine del gruppo deve essere di tipo JPG, PNG o JPEG", "error");
      return false;
    } else {
      const reader = new FileReader();
      reader.onload = () => {
        this.immagine_utente = file.name;
        this.file_immagine_utente = reader.result as string;
      }
      reader.readAsDataURL(file);

      return false;
    }
  };

  onResult(message: string, type: any) {
    window.scrollTo(0, 0);
    this.alertMessage = message;
    this.alertVisible = true;
    this.alertType = type;
    this.loading = false;
    this.loadingMembro = false
  }

  onResultMembro(message: string, type: any) {
    this.alertMessageMembro = message;
    this.alertVisibleMembro = true;
    this.alertTypeMembro = type;
  }

  onSubmit() {
    let gruppo = {
      nome: this.formGruppo.get('nome')?.value,
      descrizione: this.formGruppo.get('descrizione')?.value,
      immagine: this.immagine || '',
      immagine_file: this.file_immagine || ''
    }

    this._api.updateGruppo(+this.id_gruppo, gruppo).subscribe(data => {
      this.getData();
      this.onResult("Gruppo modificato con successo!", "success");
    }, err => {
      this._notification.create('error', 'Errore', err, { nzPlacement: 'bottomLeft', nzDuration: 5000 });
    })
  }

  onUpdateMembro() {
    let membro = {
      nome: this.formUtente.get('nome')?.value,
      cognome: this.formUtente.get('cognome')?.value,
      data_nascita: this._datePipe.transform(this.formUtente.get('data_nascita')?.value, 'yyyy-MM-dd') || '',
      comune_nascita: this.formUtente.get('comune_nascita')?.value,
      provincia_nascita: this.formUtente.get('provincia_nascita')?.value,
      nazione_nascita: this.formUtente.get('nazione_nascita')?.value,
      indirizzo: this.formUtente.get('indirizzo')?.value,
      comune: this.formUtente.get('comune')?.value,
      provincia: this.formUtente.get('provincia')?.value,
      nazione: this.formUtente.get('nazione')?.value,
      codice_fiscale: this.formUtente.get('codice_fiscale')?.value,
      telefono: this.formUtente.get('telefono')?.value,
      immagine: this.immagine_utente || '',
      immagine_file: this.file_immagine_utente || ''
    }

    this._api.updateUtenteMinorenne(this.membroSelezionato!.id, membro).subscribe(data => {
      this.modal = false;

      /* this.modale.hide();
      let backdrops = document.getElementsByClassName('modal-backdrop');

      for(let i = 0; i < backdrops.length; i++){
        backdrops[i].remove();
      } */
      document.getElementById('chiudiModale')?.click();

      this.getData();


    }, err => {
      this.onResultMembro(err, 'error');
    })
  }

}

<app-selezione></app-selezione>
<div class="home-container" id="prodotti" #negozio *ngIf="user">
  <div class="products">
    <h4 class="text-center">Scopri i nostri prodotti</h4>
    <div class="row pt-5 g-1">
      <div class="col-12 col-md-5">
        <input [(ngModel)]="filterQuery" nz-input placeholder="Cerca un prodotto" />
      </div>
      <div class="col-12 col-md-4">
        <nz-select class="w-100" nzPlaceHolder="Filtra per categoria" nzAllowClear nzShowSearch nzMode="multiple" [(ngModel)]="cat" (ngModelChange)="filtroRapido = undefined">
          <!-- <nz-option [nzValue]="''" [nzLabel]="'Mostra tutte le categorie'"></nz-option> -->
          <nz-option *ngFor="let categoria of categorie" [nzValue]="categoria.id" [nzLabel]="categoria.nome">
          </nz-option>
        </nz-select>
      </div>
      <div class="col-12 col-md">
        <button class="mx-auto w-100" nz-button (click)="toggleSoloAttivi()" [nzType]="soloAttivi ? 'dashed' : 'primary'"> {{soloAttivi ? 'Tutti i prodotti' : 'Solo disponibili'}}</button>
      </div>
    </div>
    <div class="row mt-2 gap-1">
      <div class="col-auto d-flex align-items-center">
        <span class="text-muted">Filtri rapidi:</span>
      </div>
      <div class="col-auto px-0">
        <button nz-button nzType="default" (click)="cat=[]; filtroRapido = undefined">Tutti i prodotti</button>
      </div>
      <div class="col-auto px-0">
        <button nz-button [nzType]="filtroRapido == 1 ? 'primary' : 'default'" (click)="cat=[4,13]; filtroRapido = 1">Ricariche skipass</button>
      </div>
      <div class="col-auto px-0">
        <button nz-button [nzType]="filtroRapido == 2 ? 'primary' : 'default'" (click)="cat=[1]; filtroRapido = 2">Tesseramenti Sci Club</button>
      </div>
    </div>
    <div *ngIf="loading" class="products__loading mt-5">
      <ng-template #indicatorTemplate>
        <img src="../../assets/img/riding-skiing.gif" alt="" width="250px">
      </ng-template>
      <nz-spin [nzSpinning]="loading" [nzIndicator]="indicatorTemplate" class="spinning spinner-home"></nz-spin>
    </div>

    <div class="esito-ricerca" *ngIf="!loading">
      <h3 *ngIf="prodotti.length == 0 || ((prodotti | search: filterQuery | onlyActive: soloAttivi | searchCategoria: cat)?.length == 0)" class="text-center mt-5">Nessun prodotto qui...</h3>
    </div>

    <div class="prodotti-list mt-5" *ngIf="!loading">
      <div class="prodotto-container p-1" *ngFor="let prodotto of prodotti | search: filterQuery | onlyActive: soloAttivi | searchCategoria: cat | pagination: pagina">
        <div class="prodotto border p-3">
          <div *ngIf="prodotto.immagine_file.length > 0">
            <img [src]="prodotto.immagine_file" alt="" class="immagine mb-2">
          </div>
          <nz-empty style="filter: grayscale(100%);" nzNotFoundImage="../assets/img/not_available.png" [nzNotFoundContent]="noPic" *ngIf="prodotto.immagine_file <= 0">
            <ng-template #noPic class="text-muted">
              Immagine non disponibile
            </ng-template>
          </nz-empty>
          <div class="d-flex justify-content-between">
            <div>
              <h5 style="word-break: break-word;">{{prodotto.nome}}<span class="text-muted fs-6">{{prodotto.fascia}}</span></h5>
              <span class="fs-6 text-muted">{{prodotto.categoria}}</span>
              <p class="text-muted mt-2 fs-6">{{prodotto.descrizione ? prodotto.descrizione : 'Nessuna descrizione'}}</p>
            </div>
            <div>
              <span class="fs-5">{{prodotto.prezzo | currency: 'EUR'}}</span>
            </div>
          </div>
          <div class="tasto-cta w-100">
            <button class="w-100 cursor-pointer px-3 py-1 d-flex align-items-center justify-content-center gap-2 border border-2 border-secondary text-muted" *ngIf="prodotto.disabilitato || (disable_all && !prodotto.obbligatorio)" disabled nz-tooltip [nzTooltipTitle]="(disable_all && !prodotto.obbligatorio) ? 'Aggiungi prima al carrello il tesseramento dello Sci Club'  : (prodotto.attivo ? 'Prodotto già acquistato e attivo!' : 'Prodotto non disponibile')">
              <span>Non disponibile</span>
              <i class="fs-4 bi bi-cart-x"></i>
            </button>
            <button class="w-100 cursor-pointer px-3 py-1 d-flex align-items-center justify-content-center gap-2" [ngClass]="prodotto.selezionato ? 'text-dark bg-white border border-2 border-dark' :' text-white bg-primary border border-2 border-primary'" *ngIf="!prodotto.disabilitato && !(disable_all && !prodotto.obbligatorio)" (click)="prodotto.selezionato ? rimuoviCarrello(prodotto) : addCarrello(prodotto)">
              <span>{{prodotto.selezionato ? 'Rimuovi dal carrello' : 'Aggiungi al carrello'}}</span>
              <i class="bi fs-4" [ngClass]="prodotto.selezionato ? 'bi-cart-dash' : 'bi-cart-plus-fill'"></i>
            </button>
          </div>
        </div>
      </div>
    </div>

    <div class="row mt-5" *ngIf="(prodotti | search: filterQuery | onlyActive: soloAttivi | searchCategoria: cat)?.length > 0">
      <div class="col" style="text-align: center;">
        <nz-pagination [nzPageIndex]="1" [nzTotal]="(prodotti | search: filterQuery | onlyActive: soloAttivi | searchCategoria: cat)?.length + 1" (nzPageIndexChange)="onPageIndexChange($event, negozio)"></nz-pagination>
      </div>
    </div>
  </div>
</div>

<!-- <nz-back-top [nzTemplate]="tpl" [nzVisibilityHeight]="-100">
  <ng-template #tpl>

    <nz-badge nzSize="default" class="cart-floating" [nzCount]="carrello?.prodotti?.length" (click)="_cart.openCart();">
      <button nz-button nzType="primary" nzSize="large" nzShape="circle" ><i nz-icon nzType="shopping-cart" class="cart"></i></button>
    </nz-badge>
    
  </ng-template>
</nz-back-top> -->


<app-floating-cart></app-floating-cart>

<router-outlet></router-outlet>
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-tutorial-gruppi',
  templateUrl: './tutorial-gruppi.component.html',
  styleUrls: ['./tutorial-gruppi.component.scss']
})
export class TutorialGruppiComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}

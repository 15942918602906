import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { NzUploadFile } from 'ng-zorro-antd/upload';
import { ApiService } from 'src/app/services/api.service';
import { TokenStorageService } from 'src/app/services/token-storage.service';
import { Gruppo, Membri } from 'src/app/shared/interface.model';

@Component({
  selector: 'app-add-gruppo',
  templateUrl: './add-gruppo.component.html',
  styleUrls: ['./add-gruppo.component.scss']
})
export class AddGruppoComponent implements OnInit {

  gruppo?: Gruppo;
  membri: Membri[] = [];
  id_gruppo!: string;

  user_email!: string;
  user_id!: number;

  alertMessage: string = '';
  alertType: "success" | "info" | "warning" | "error" = 'success';
  alertVisible: boolean = false;
  loading: boolean = false;

  file_immagine!: string;
  immagine!: string;

  fileList: NzUploadFile[] = [];

  formGruppo!: FormGroup;
  formMembro!: FormGroup;

  constructor(private _api: ApiService, private _token: TokenStorageService, private router: Router, private _location: Location, private _notification: NzNotificationService) { }

  ngOnInit(): void {

    this.formGruppo = new FormGroup({
      nome: new FormControl(null, Validators.required),
      descrizione: new FormControl(null)
    })

    const { id, cognome, nome, email } = this._token.getUser();
    this.user_id = id;
    this.user_email = email;

  }

  beforeUpload = (file: any): boolean => {
    this.fileList = this.fileList.concat(file);

    const isJpgOrPng = this.fileList.length > 0 ? this.fileList[0]!.type === 'image/jpeg' || this.fileList[0]!.type === 'image/png' : true;

    if (!isJpgOrPng) {
      this.alertMessage = "L'immagine del gruppo deve essere di tipo JPG, PNG o JPEG";
      this.alertVisible = true;
      this.alertType = 'error';
      return false;
    } else {
      const reader = new FileReader();
      reader.onload = () => {
        this.immagine = file.name;
        this.file_immagine = reader.result as string;
      }
      reader.readAsDataURL(file);

      return false;
    }
  }

  onGoBack() {    
    this._location.back();
  }

  onSubmit() {

    let gruppo = {
      id_cliente: this.user_id,
      nome: this.formGruppo.get('nome')?.value,
      descrizione: this.formGruppo.get('descrizione')?.value,
      immagine: this.immagine || '',
      immagine_file: this.file_immagine || ''
    }

    this._api.addGruppo(gruppo).subscribe(data => {
      this.router.navigate(['/gruppi']);
    }, err => {
      this._notification.create('error', 'Errore', err, { nzPlacement: 'bottomLeft', nzDuration: 5000 });      
    })

  }


}

import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { ApiService } from './api.service';
import { TokenStorageService } from './token-storage.service';
import { Ordine } from '../shared/interface.model';

@Injectable({
  providedIn: 'root',
})
export class TesseramentoGuardService implements CanActivate {
  constructor(private _router: Router, private _token: TokenStorageService, private _api: ApiService) { }
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): any {
    return new Observable<boolean>(obs => {

      let user = this._token.getUser();
      // recupero gli ordini
      this._api.getOrdiniUtente(user.id).subscribe((ordini: Ordine[]) => {

        let ordini_non_pagati = ordini.filter((ordine: Ordine) => ordine.stato.toLocaleLowerCase() == 'non pagato');
        let obbligatorio = false

        ordini_non_pagati.forEach((ordine) => {
          if (ordine.id_prodotto == 3)
            obbligatorio = true
        })

        if (obbligatorio) {
          this._router.navigate(["/ordini"], { queryParams: { pagamento: 'true' } });
          obs.next(false);
        } else {
          obs.next(true);
        }

      }, err => {
        this._router.navigateByUrl("");
        obs.next(false);
      })
    });
  }
}

import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ApiService } from 'src/app/services/api.service';
import { TokenStorageService } from 'src/app/services/token-storage.service';

@Component({
  selector: 'app-richiesta-reset-password',
  templateUrl: './richiesta-reset-password.component.html',
  styleUrls: ['./richiesta-reset-password.component.scss']
})
export class RichiestaResetPasswordComponent implements OnInit {

  loading: boolean = false;
  alertMessage: string = '';
  alertType: "success" | "info" | "warning" | "error" = 'success';
  alertVisible: boolean = false;

  formReset!: FormGroup;

  constructor(private _api: ApiService, private _location: Location, private _token: TokenStorageService) { }

  ngOnInit(): void {

    let body = document.getElementsByTagName('body')[0];
    body.classList.add('noscroll')

    this.formReset = new FormGroup({
      email: new FormControl(null, Validators.required)
    })
  }

  enableScroll() {
    let body = document.getElementsByTagName('body')[0];
    body.classList.remove('noscroll')
  }

  onGoBack() {
    this.enableScroll();
    this._location.back();
  }

  onClose() {
    this.enableScroll();
    this._token.deleteMembro();
  }

  onSubmit(): void {    
    
    let email = this.formReset.get('email')?.value;
    this.loading = true;

    this._api.richiestaReset(email).subscribe((res) => {
      this.alertMessage = "Un'email è stata inviata all'indirizzo inserito";
      this.alertVisible = true;
      this.alertType = 'success';
      this.loading = false;      
    }, (err) => {
      this.alertMessage = err;
      this.alertVisible = true;
      this.alertType = 'error';
      this.loading = false;
    });
  }

}

import { DatePipe, Location } from '@angular/common';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { ApiService } from 'src/app/services/api.service';
import { TokenStorageService } from 'src/app/services/token-storage.service';
import { TokenGruppo } from 'src/app/shared/interface.model';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss'],
})

export class RegisterComponent implements OnInit {

  public disabledDate = (current: Date): boolean => current > new Date();

  constructor(private _api: ApiService, private _router: Router, private _token: TokenStorageService, private _notification: NzNotificationService, private _location: Location, private _datePipe: DatePipe) { }

  @ViewChild('modal') modal!: ElementRef;
  @ViewChild('form', { static: false }) form!: ElementRef;

  formRegistrazione!: FormGroup;
  error: string = '';
  loading: boolean = false;

  passwordVisible: boolean = false;
  passwordConfirmVisible: boolean = false;

  token_membro: string = '';
  data_token_membro!: TokenGruppo;  

  ngOnInit(): void {    

    if (sessionStorage['auth-token']) {
      this._router.navigate(['']);
      return;
    }

    let body = document.getElementsByTagName('body')[0];
    body.classList.add('noscroll')

    this.formRegistrazione = new FormGroup({
      nome: new FormControl(null, Validators.required),
      cognome: new FormControl(null, Validators.required),
      email: new FormControl(null, Validators.required),
      confirm_email: new FormControl(null, Validators.required),
      password: new FormControl(null, Validators.required),
      confirm_password: new FormControl(null, Validators.required),            
      data_nascita: new FormControl(null, Validators.required),      
      comune_nascita: new FormControl(null, Validators.required),
      provincia_nascita: new FormControl(null, Validators.required),
      nazione_nascita: new FormControl(null, Validators.required),                  
      indirizzo: new FormControl(null, Validators.required),      
      comune: new FormControl(null),
      provincia: new FormControl(null),
      nazione: new FormControl(null),      
      codice_fiscale: new FormControl(null, Validators.required),
      telefono: new FormControl(null),
      newsletter: new FormControl(null),
      privacy: new FormControl(null, [Validators.required, Validators.requiredTrue])
    })

    // Recupero i token
    this.token_membro = this._token.getMembro();    

    // Se c'è il token del gruppo procedo
    if (this.token_membro.length > 0) {
      this._api.chekTokenGruppo(this.token_membro).subscribe(data => {
        if (data.id_gruppo) {
          this.data_token_membro = data;    

          this.formRegistrazione.patchValue({
            email: data.email,
            confirm_email: data.email,
            nome: data.nome,
            cognome: data.cognome
          })
        } else {     
          this._token.deleteMembro();
          this._notification.create('error', 'Si è verificato un errore', "Non è stato possibile accettare l'invito al gruppo, contattare l'assistenza oppure provare a riaprire il link arrivato tramite email!", { nzPlacement: 'bottomLeft', nzDuration: 5000 });
        }
        
      }, err => {
        this._notification.create('error', 'Errore', err, { nzPlacement: 'bottomLeft', nzDuration: 5000 });      
      })
    } else {
      if (this._token.getToken().length > 0) {
        this._router.navigate(['']);
        return;
      }
    }
  }

  enableScroll() {
    let body = document.getElementsByTagName('body')[0];
    body.classList.remove('noscroll')
  }

  onGoBack() {
    this.enableScroll();    
    this._location.back();
  }

  onClose() {
    this.enableScroll();
    this._token.deleteMembro();
  }

  onSubmit(): void {
    this.error = '';

    let cliente = {
      nome: this.formRegistrazione.get('nome')?.value,
      cognome: this.formRegistrazione.get('cognome')?.value,
      email: this.formRegistrazione.get('email')?.value,
      confirm_email: this.formRegistrazione.get('confirm_email')?.value,
      password: this.formRegistrazione.get('password')?.value,
      confirm_password: this.formRegistrazione.get('confirm_password')?.value,
      data_nascita: this._datePipe.transform(this.formRegistrazione.get('data_nascita')?.value, 'yyyy-MM-dd'),
      comune_nascita: this.formRegistrazione.get('comune_nascita')?.value,
      provincia_nascita: this.formRegistrazione.get('provincia_nascita')?.value,
      nazione_nascita: this.formRegistrazione.get('nazione_nascita')?.value,
      indirizzo: this.formRegistrazione.get('indirizzo')?.value,
      comune: this.formRegistrazione.get('comune')?.value,
      provincia: this.formRegistrazione.get('provincia')?.value,
      nazione: this.formRegistrazione.get('nazione')?.value,
      codice_fiscale: this.formRegistrazione.get('codice_fiscale')?.value,
      telefono: this.formRegistrazione.get('telefono')?.value,
      newsletter: this.formRegistrazione.get('newsletter')?.value || false,
      privacy: this.formRegistrazione.get('privacy')?.value || false 
    }

    this.loading = true;
    this._api.registrazione(cliente).subscribe((res) => {

      this.loading = false;
      this._router.navigate(['/login']);
      this._notification.create('success', 'Registrazione completata', "La registrazione è stata completata con successo! Effettua il login per poter acquistare i nostri prodotti!", { nzPlacement: 'bottomLeft', nzDuration: 5000 });

      if (this.token_membro.length > 0) {        

        this._api.addMembroGruppo(this.data_token_membro.id_gruppo, +this.data_token_membro.id_utente, res.id).subscribe(data => {                    

          this._token.deleteMembro();
          this._notification.create('success', 'Benvenuto nel gruppo ' + this.data_token_membro.nome_gruppo, "L'invito è stato accettato!", { nzPlacement: 'bottomLeft', nzDuration: 10000 });          

        }, err => {
          this._notification.create('error', 'Errore', err, { nzPlacement: 'bottomLeft', nzDuration: 10000 });
        })
      }
    }, (err) => {
      this.loading = false;
      if (err == 'Errore - errore eta') {
        this.modal.nativeElement.click()
      } else {
        this.error = err;        
        this.form.nativeElement.scrollTop = 0;
      }      
    })
  }
}

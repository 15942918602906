<div class="footer-container text-muted bg-light">
  <div class="container">
    <div class="row text-center text-md-start">
      <div class="col-md-4 sezione">
        <h5>Links</h5>
        <a routerLink="/privacy">Informativa privacy</a><br />
        <a routerLink="/termini-condizioni">Condizioni e termini d'uso</a><br />        
        <a href="https://www.sciclubbussoleno.com/" target="_blank">Sito principale</a><br />
        <a href="https://www.facebook.com/bussoleno.sciclub" target="_blank">Facebook</a><br />
        <a href="https://www.flickr.com/photos/129755006@N03/" target="_blank">Flickr</a><br />        
      </div>
      <div class="col-md-4 sezione">
        <h5>F.A.Q.</h5>
        <a routerLink="/informativa-tesseramento">Tessera Sociale</a><br />
        <a routerLink="/informativa-assicurazione">Assicurazione</a><br />
        <a routerLink="/informativa-skicard-vialattea">Skicard Vialattea</a><br />
        <a routerLink="/informativa-skipass-open">Card Skipass Open</a><br />         
      </div>
      <div class="col-md-4 sezione">
        <h5>Contatti</h5>
        <span>SCI CLUB BUSSOLENO ASD - c.f. 86504790014</span><br />
        <address class="m-0 p-0"><a href="https://goo.gl/maps/795RoSEdYAYkVGMi8">P.za Don Aldo Grisa 1,10053 Bussoleno</a></address>
        <a href="mailto:supporto@sciclubbussolenovalsusa.com">supporto@sciclubbussolenovalsusa.com</a><br />
        <a href="mailto:info@sciclubbussoleno.com">info@sciclubbussoleno.com</a><br />
      </div>
    </div>
  </div>
</div>
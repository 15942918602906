<ng-template #indicatorTemplate>
    <img src="../../assets/img/riding-skiing.gif" alt="" width="250px">
</ng-template>
<nz-spin [nzSpinning]="caricamento" [nzIndicator]="indicatorTemplate"  class="spinning spinner" >
    <div class="container border p-0">
        <div class="row p-4 text-center">
            <div class="col">
                <h4>Grazie per il tuo acquisto!</h4>                
            </div>
        </div>
        <div class="row border-top m-0 p-0">
            <div class="p-3 col text-center" style="margin-top: 30px; margin-bottom: 30px;">
                <div>                    
                    <p class="m-0">L'ordine è andato a buon fine. <br />
                        {{ordine ? "Per tornare allo shop premi sul pulsante qua sottostante oppure vai alla sezione 'I MIEI ORDINI'!" : "Per attivare i prodotti ordinati, vai alla sezione 'I MIEI ORDINI' e seleziona i prodotti che desideri pagare!"}}
                    </p>
                </div>
            </div>
        </div>
        <div class="row p-3 border-top m-0">
            <div class="col d-flex align-items-center justify-content-end">                
                <button nz-button nzSize="large" class="d-inline btn rounded-0 border me-2" routerLink="/">Torna allo shop</button>
                <button nz-button nzSize="large" nzType="primary" class="d-inline rounded-0 border" routerLink="/ordini">I miei ordini</button>
            </div>
        </div>
    </div>
</nz-spin>
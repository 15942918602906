import { Component, OnInit } from '@angular/core';
import { TokenStorageService } from '../services/token-storage.service';
import { CartService } from '../services/cart.service';
import { ApiService } from '../services/api.service';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { AuthUser, CarrelloNonPagati, Utente } from '../shared/interface.model';
import { FormControl, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-cassa-non-pagati',
  templateUrl: './cassa-non-pagati.component.html',
  styleUrls: ['./cassa-non-pagati.component.scss']
})
export class CassaNonPagatiComponent implements OnInit {

  constructor(private _token: TokenStorageService, private _cart: CartService, private _api: ApiService, private _router: Router, private _location: Location, private _notification: NzNotificationService) { }

  loading: boolean = false;
  caricamento: boolean = false;

  user?: AuthUser;
  utente!: Utente;

  carrello!: CarrelloNonPagati;
  metodo = { bonifico: true, carta: true, satispay: true };

  formMetodo!: FormGroup;

  ngOnInit(): void {

    this.formMetodo = new FormGroup({
      metodo: new FormControl(null, Validators.required)
    });

    // Recupero l'utente
    this.user = this._token.getUser();

    // Recupero il carrello
    this._cart.subjectCarrelloNonPagati.subscribe((cartData) => {
      
      this.carrello = cartData;

      this.getMetodo();

      // Se non ho prodotti torno indietro
      if (this.carrello.ordini.length <= 0) {
        this._router.navigate(['/ordini'])
      }
    });

  }

  onRimuoviProdotto(id: number): void {
    this._cart.removeOrdine(id);
  }

  getMetodo() {

    // Ciclo per i prodotti nel carrello
    for (let ordine of this.carrello.ordini) {

      // Recupero le info del prodotto
      this._api.getProdotto(ordine.id_prodotto).subscribe(prodotto => {

        // Se non è presente il flag per i bonifici disabilito il metodo
        if (!prodotto.bonifico) {
          this.metodo.bonifico = false;
        }
        // Se non è presente il flag per la carta disabilito il metodo
        if (!prodotto.carta) {
          this.metodo.carta = false;
        }
        // Se non è presente il flag per satispay disabilito il metodo
        if (!prodotto.satispay) {
          this.metodo.satispay = false;
        }
      }, err => {
        this._notification.create('error', 'Errore', err, { nzPlacement: 'bottomLeft', nzDuration: 5000 });
      })
    }

  }

  onGoBack() {
    this._location.back();
  }

  onPagamento() {
    this.caricamento = true;

    // recupero il metodo di pagamento
    let metodo = this.formMetodo.get('metodo')?.value;    

    // formo l'ordine inserendo i dati inseriti dal cliente
    let ordine = {
      metodo: metodo,            
      carrello: this.carrello,
    }

    // in base al metodo selezionato recupero l'url dove deve essere reinderizzato il cliente
    if (metodo == 'Carta di credito') {

      this._api.getPagamentoCartaNonPagati(ordine).subscribe(data => {
        localStorage.setItem('token_pagamento', data.token_pagamento);
        window.location.href = data.url;
        this.caricamento = false;
      }, err => {
        this.caricamento = false;
        this._notification.create('error', 'Errore', err, { nzPlacement: 'bottomLeft', nzDuration: 5000 });
      })

    } else {

      this._api.getPagamentoSatispayNonPagati(ordine).subscribe(data => {
        localStorage.setItem('satispay', data.satispay);
        window.location.href = data.url;
        this.caricamento = false;
      }, err => {
        this.caricamento = false;
        this._notification.create('error', 'Errore', err, { nzPlacement: 'bottomLeft', nzDuration: 5000 });
      })

    }
  }
}

import { Component, OnInit } from '@angular/core';
import { ApiService } from '../services/api.service';
import { TokenStorageService } from '../services/token-storage.service';
import { AuthUser, Ordine, Tesseramento } from '../shared/interface.model';
import { NzNotificationService } from 'ng-zorro-antd/notification';

@Component({
  selector: 'app-storico-ordini',
  templateUrl: './storico-ordini.component.html',
  styleUrls: ['./storico-ordini.component.scss']
})
export class StoricoOrdiniComponent implements OnInit {

  constructor(private _api: ApiService, private _token: TokenStorageService, private _notification: NzNotificationService) { }

  user?: AuthUser;

  ordini: Ordine[] = [];    
  tesseramenti: Tesseramento[] = [];

  ricerca_attivi: string = '';
  ricerca_ordini: string = '';

  expandSet = new Set<number>();

  ngOnInit(): void {

    this.user = this._token.getUser();

    // recupero i tesseramenti attivi dell'utente loggato
    this._api.getTesseramentiOrdiniUtente(this.user!.id).subscribe(data => {
      this.tesseramenti = data.tesseramenti
      this.ordini = data.ordini;

      for (let tesseramento of this.tesseramenti)       {
        this._api.getProdotto(tesseramento.id_prodotto).subscribe(prodotto => {
          tesseramento.id_categoria = prodotto.id_categoria;
        })
      }

    }, err => {
      this._notification.create('error', 'Errore', err, { nzPlacement: 'bottomLeft', nzDuration: 5000 });
    });
  }

  onExpandChange(id: number, checked: boolean): void {
    if (checked) {
      this.expandSet.add(id);
      // let ordini = this.ordini.filter(ord => ord.id_ordine = id)
    } else {
      this.expandSet.delete(id);
    }
  }

  onOrdinamentoMembro(a: Tesseramento, b: Tesseramento) {
    let cliente_a = a.id_gruppo ? a.cliente : '-';
    let cliente_b = b.id_gruppo ? b.cliente : '-';
    return cliente_a.localeCompare(cliente_b)
  }

  onOrdinamentoProdotto(a: Tesseramento, b: Tesseramento) {
    return a.prodotto.localeCompare(b.prodotto)
  }

  onOrdinamentoCodice(a: Tesseramento, b: Tesseramento) {
    return (a.codice_tesseramento || '').localeCompare((b.codice_tesseramento || ''))
  }

  onOrdinamentoPrezzo(a: Tesseramento, b: Tesseramento) {
    return a.prezzo - b.prezzo;
  }

  onOrdinamentoDataScadenza(a: Tesseramento, b: Tesseramento) {
    return a.fine_validita.toString().localeCompare(b.fine_validita.toString())
  }

  onOrdinamentoData(a: Tesseramento, b: Tesseramento) {
    return a.data_creazione.toString().localeCompare(b.data_creazione.toString())
  }

  onOrdinamentoOrdine(a: Tesseramento, b: Tesseramento) {
    return (a.id_ordine || 0) - (b.id_ordine || 0)
  }

  onOrdinamentoProdotti(a: Tesseramento, b: Tesseramento) {
    return a.prodotto.localeCompare(b.prodotto)
  }

  onOrdinamentoClienti(a: Tesseramento, b: Tesseramento) {
    return a.cliente.localeCompare(b.cliente)
  }

  onOrdinamentoTotale(a: Tesseramento, b: Tesseramento) {
    return a.prezzo - b.prezzo;
  }

  onOrdinamentoMetodo(a: Tesseramento, b: Tesseramento) {
    return a.metodo.localeCompare(b.metodo)
  }

  onOrdinamentoDataAcquisto(a: Tesseramento, b: Tesseramento) {
    let data_creazione_a = a.id_ordine ? a.data_creazione : a.fine_validita;
    let data_creazione_b = b.id_ordine ? b.data_creazione : b.fine_validita;
    return data_creazione_a.toString().localeCompare(data_creazione_b.toString())
  }


}

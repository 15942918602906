import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-termini-uso',
  templateUrl: './termini-uso.component.html',
  styleUrls: ['./termini-uso.component.scss']
})
export class TerminiUsoComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}

import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService } from 'src/app/services/api.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { TokenStorageService } from 'src/app/services/token-storage.service';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { Location } from '@angular/common';
import { TokenGruppo } from 'src/app/shared/interface.model';
import { RedirectService } from 'src/app/shared/redirect.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {  

  constructor(private _redirect: RedirectService, private _api: ApiService, private _token: TokenStorageService, private _router: Router, private _route: ActivatedRoute, private _location: Location, private _notification: NzNotificationService) { }

  error: string = '';
  loading: boolean = false;
  passwordVisible: boolean = false;

  token: string = '';
  token_membro: string = '';
  
  url: string = '';
  sezione: string = '';

  data_token_membro!: TokenGruppo;

  errore_eta: boolean = false;
  pagamento: boolean = false;

  formLogin!: FormGroup;

  ngOnInit(): void {

    this.formLogin = new FormGroup({
      email: new FormControl(null, Validators.required),
      password: new FormControl(null, Validators.required)
    })

    if (sessionStorage['auth-token']) {
      this._router.navigate(['']);
      return;
    }

    let body = document.getElementsByTagName('body')[0];
    body.classList.add('noscroll');

    this.url = this._router.url;

    this._route.queryParams.subscribe(params => {
      this.sezione = params['section'] || '';

      this.token_membro = this._token.getMembro();
      if (this.token_membro.length > 0) {
        this._api.chekTokenGruppo(this.token_membro).subscribe(data => {
          if (data.id_gruppo) {
            this.data_token_membro = data;
          } else {
            this._token.deleteMembro();
            this._notification.create('error', 'Si è verificato un errore', "Non è stato possibile accettare l'invito al gruppo, contattare l'assistenza oppure provare a riaprire il link arrivato tramite email!", { nzPlacement: 'bottomLeft', nzDuration: 5000 });
          }
        }, err => {
          this._notification.create('error', 'Errore', err, { nzPlacement: 'bottomLeft', nzDuration: 5000 });
        })
      }

      if (this.url.indexOf('esito-pagamento') > -1) {
        this.pagamento = true;        
      }
    });

  }

  enableScroll() {
    let body = document.getElementsByTagName('body')[0];
    body.classList.remove('noscroll')
  }

  onGoBack() {
    this.enableScroll();
    this._location.back();
  }

  onClose() {
    this.enableScroll();
    this._token.deleteMembro();
    this._redirect.clearTarget();
  }

  onSubmit(): void {
    this.loading = true;
    this.error = '';

    let login = {
      email: this.formLogin.get('email')?.value,
      password: this.formLogin.get('password')?.value
    }

    this._api.login(login.email, login.password).subscribe((res) => {
      this.loading = false;

      if (res.data.eta < 18) {
        this.errore_eta = true;
      } else {

        this._token.setToken(res.token);
        this._token.setUser(res.data);
        this.enableScroll();

        if (this.token_membro.length > 0) {

          let invito = {
            id_cliente_invitato: res.data.id.toString(),
            id_cliente: this.data_token_membro.id_utente.toString(),
            id_gruppo: this.data_token_membro.id_gruppo.toString()
          }

          this._api.addInvito(invito).subscribe(data => {

            if (this.sezione == 'gruppi') {
              this._router.navigate(['/gruppi']);
            } else {
              this._router.navigate(['/']);
            }

          }, err => {
            this._notification.create('error', 'Errore', err, { nzPlacement: 'bottomLeft', nzDuration: 5000 });
          })

        } else {

          if (this.sezione == 'gruppi') {
            this._router.navigate(['/gruppi']);
          } else if (this.sezione == 'cassa') {
            this._router.navigate(['/cassa']);
          } else if (this.url.indexOf('esito-pagamento') > -1) {            
            this._router.navigate(['/esito-pagamento'], { queryParamsHandling: 'merge' });
          } else {            
            this._router.navigate(['/']);
          }
        }
      }

    }, (err) => {
      this.error = err;
      this.loading = false;
    })

  }
}

import { Component, OnInit } from '@angular/core';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { ApiService } from 'src/app/services/api.service';
import { PuntoRitiro } from 'src/app/shared/interface.model';

@Component({
  selector: 'app-informativa-skicard-vialattea',
  templateUrl: './informativa-skicard-vialattea.component.html',
  styleUrls: ['./informativa-skicard-vialattea.component.scss']
})
export class InformativaSkicardVialatteaComponent implements OnInit {

  constructor(private _api: ApiService, private _notification: NzNotificationService) { }

  punti_ritiro: PuntoRitiro[] = [];

  ngOnInit(): void {

    this._api.getPuntiRitiro().subscribe(data => {
      this.punti_ritiro = data;
    }, err => {
      this._notification.create('error', 'Errore', err, { nzPlacement: 'bottomLeft', nzDuration: 5000 });
    })

  }

}

import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService } from '../services/api.service';
import { CartService } from '../services/cart.service';
import { TokenStorageService } from '../services/token-storage.service';
import { AuthUser } from '../shared/interface.model';

@Component({
  selector: 'app-esito-pagamento',
  templateUrl: './esito-pagamento.component.html',
  styleUrls: ['./esito-pagamento.component.scss']
})
export class EsitoPagamentoComponent implements OnInit {

  constructor(private _api: ApiService, private _token: TokenStorageService, private _cart: CartService, private _route: ActivatedRoute, private _router: Router) { }

  token: string = '';
  token_aexerve: string = '';
  satispay: string = '';

  ordine!: any;

  caricamento: boolean = false;
  stato_ordine: boolean = false;

  user?: AuthUser;

  ngOnInit(): void {

    this.caricamento = true;

    // Recupero i dati dell'utente salvati nel sessionStorage    
    this._token.subjectUser.subscribe((user) => {
      this.user = user == undefined ? this._token.getUser() : user;

      if (this.user) {
        // Recupero i dati in base all'età dell'utente
        this.getData();
      } else {
        this._router.navigate(['login'], { relativeTo: this._route, queryParamsHandling: 'merge' });
      }
    })
  }

  getData() {

    // Recupero il codice di satispay e il token del pagamento di aexerve se ci sono
    this.satispay = localStorage.getItem('satispay') || '';
    let token_pagamento = localStorage.getItem('token_pagamento') || '';

    this._route.queryParams.subscribe(params => {

      // Recupero il token
      this.token = params['token'];
      this.token_aexerve = params['b'];

      // Se non ci sono i token torno al home page
      if (!this.token && !this.token_aexerve) {
        this._router.navigate(['/']);
      }

      // Se è presente il token del nostro shop, è un pagamento di satispay
      if (this.token) {

        // Controllo che il token sia corretto
        this._api.checkTokenPagamento(this.token).subscribe(ord => {
          this.ordine = ord;

          if (this.ordine.carrello.length <= 0) {
            this._router.navigate(['/']);
          } else {

            // Se ho il codice di satispay
            if (this.satispay.length > 0) {

              // Controllo che il pagamento di satispay sia andato a buon fine
              this._api.getDettaglioPagamentoSatispay(this.satispay).subscribe(sat => {
                if (sat.status == 'ACCEPTED') {

                  if (ord.stato == 'pagato') {
                    // Aggiungo l'ordine al database
                    this.onPagamento(this.token);
                  } else {
                    this.onPagamentoDopo(this.token)
                  }

                } else if (sat.status == 'CANCELED' || sat.status == 'PENDING') {

                  // Se non è stato accettato mostro l'errore
                  this.stato_ordine = false;
                  this.caricamento = false;
                }
              }, err => {
                this.stato_ordine = false;
                this.caricamento = false;
              })
            } else {
              this._router.navigate(['/']);
            }
          }

        }, err => {
          this.stato_ordine = false;
          this.caricamento = false;
        })
      }

      // Se è presente il token di aexerve procedo
      if (this.token_aexerve) {

        if (token_pagamento.length > 0) {
          this._api.checkTokenPagamentoAexerve(this.token_aexerve, token_pagamento).subscribe(data => {

            this.ordine = data;

            if (this.ordine.carrello.length <= 0) {
              this._router.navigate(['/']);
            } else {

              if (data.stato == 'pagato') {
                // Aggiungo l'ordine al database
                this.onPagamento(token_pagamento);
              } else {
                this.onPagamentoDopo(token_pagamento)
              }
            }

          }, err => {
            this.stato_ordine = false;
            this.caricamento = false;
          })
        } else {
          this._router.navigate(['/']);
        }

      }
    })
  }

  onPagamento(token: string) {
    let ordine = {
      metodo: this.ordine.metodo,
      nota: this.ordine.nota,
      eseguitoDa: this.ordine.eseguitoDa,
      id_gruppo: this.ordine.id_gruppo,
      gruppo: this.ordine.gruppo,
      carrello: this.ordine.carrello,
      codici_sconto: this.ordine.codici_sconto,
      token: token,
      stato: 'pagato'
    }

    this._api.addOrdine(ordine).subscribe(data => {
      if (data.code == 200) {
        this.stato_ordine = true;
        this.caricamento = false;
        localStorage.removeItem('token_pagamento')
        localStorage.removeItem('satispay')
        this._cart.clearCart();
        this._cart.clearCartGruppo();
      } else if (data.code == 300) {
        this.stato_ordine = true;
        this.caricamento = false;
        this._cart.clearCart();
        this._cart.clearCartGruppo();
      }
    }, err => {
      this.stato_ordine = false;
      this.caricamento = false;
    });

  }

  onPagamentoDopo(token: string) {

    let ordine = {
      metodo: this.ordine.metodo,
      carrello: this.ordine.carrello,
      token: token
    }

    this._api.addOrdineNonPagati(ordine).subscribe(data => {
      if (data.code == 200) {
        this.stato_ordine = true;
        this.caricamento = false;
        this._cart.clearCartNonPagati();
      } else if (data.code == 300) {
        this.stato_ordine = true;
        this.caricamento = false;
        this._cart.clearCartNonPagati();
      }
    }, err => {
      this.stato_ordine = false;
      this.caricamento = false;
    });

  }

}

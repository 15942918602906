import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { ApiService } from 'src/app/services/api.service';
import { CartService } from 'src/app/services/cart.service';
import { TokenStorageService } from 'src/app/services/token-storage.service';
import { AuthUser, CarrelloGruppo, CarrelloMembro, Gruppo, Membri, Prodotto, Tesseramento, Utente } from 'src/app/shared/interface.model';
import { Categorie } from 'src/app/shared/interface.model';
@Component({
  selector: 'app-acquisto-gruppo',
  templateUrl: './acquisto-gruppo.component.html',
  styleUrls: ['./acquisto-gruppo.component.scss']
})
export class AcquistoGruppoComponent implements OnInit {

  constructor(private _api: ApiService, private _token: TokenStorageService, private _cart: CartService, private _route: ActivatedRoute, private _router: Router, private _notification: NzNotificationService) { }

  public disabledDate = (current: Date): boolean => current > new Date();

  caricamento: boolean = true;
  disable_all: boolean = true;
  disable_skipass: boolean = true;

  user!: AuthUser;

  gruppo?: Gruppo;
  membri: Membri[] = [];
  membroSelezionato?: Membri = undefined;
  categorie: Categorie[] = [];
  cat: any;

  prodotti: Prodotto[] = [];
  prodottiSelezionati: Prodotto[] = [];
  tesseramenti: Tesseramento[] = [];
  prodottoInfo: Prodotto | undefined;

  totale: number = 0;

  carrello: CarrelloGruppo = { gruppo: 0, carrello: [] };
  contaProdotti: any = {};

  img_profilo: string = '';

  infoUtente!: FormGroup;

  ricerca: string = '';

  soloAttivi: boolean = true;

  toggleSoloAttivi() {
    this.soloAttivi = !this.soloAttivi;
  }

  setProdottoInfo(prodotto: Prodotto) {
    this.prodottoInfo = prodotto;
  }

  ngOnInit(): void {

    this.infoUtente = new FormGroup({
      nome: new FormControl(null),
      cognome: new FormControl(null),
      data_nascita: new FormControl(null),
      codice_fiscale: new FormControl(null)
    })

    // recupero le categorie
    this._api.getCategorie().subscribe((categorie: Categorie[]) => {
      this.categorie = categorie;

      // recupero i dati dell'utente loggato e del gruppo con cui sta acquistando
      this.getDataUser();

    }, (err) => {
      this._router.navigate(['/gruppi']);
    })
  }

  getDataUser() {

    this.user = this._token.getUser();
    this.infoUtente.disable();

    // recupero route params (id gruppo)
    this._route.params.subscribe(params => {
      let id_gruppo = params['id'];

      this.carrello.gruppo = id_gruppo;

      // recupero dati gruppo
      this._api.getGruppo(id_gruppo).subscribe((gruppo: Gruppo) => {
        this.gruppo = gruppo;

        // se il cliente loggato non è il capogruppo torno indietro
        if (gruppo.id_cliente != this.user!.id) {
          this._router.navigate(['/gruppi']);
        }

        // ottengo membri gruppo
        this._api.getMembriGruppo(id_gruppo).subscribe((membri: Membri[]) => {
          this.membri = membri;

          this.caricamento = false;
          this.getCarrello();

        }, (err) => {
          this._router.navigate(['/gruppi']);
        })
      }, (err) => {
        this._router.navigate(['/gruppi']);
      })
    })
  }

  selezMembro(membro: Membri | undefined) {

    // se il membro era già selezionato non procedo
    if (membro === this.membroSelezionato || !membro)
      return;

    this.caricamento = true;

    this.totale = 0;
    this.disable_all = true;
    // this.disable_skipass = true;

    // creo il record nel carrello per il membro selezionato se non era presente
    const alreadyExists = this.carrello.carrello.find((carrello) => membro.id === carrello.cliente.id);
    if (!alreadyExists) { this.carrello.carrello.push({ cliente: membro, prodotti: [] }) }

    this.membroSelezionato = membro;

    // recupero i dati del membro (immagine e data di nascita)
    this._api.getUtente(membro.id).subscribe((utente: Utente) => {

      this.infoUtente.patchValue(utente);
      this.img_profilo = utente.immagine_file.length > 0 ? utente.immagine_file : '../../assets/img/avatar.jpg';

      // calcolo l'età del membro
      let differenza = Math.abs(Date.now() - new Date(utente.data_nascita).getTime());
      let eta = Math.floor((differenza / (1000 * 3600 * 24)) / 365.25);

      // recupero i prodotti per la sua età
      this._api.getProdotti(utente.id, eta).subscribe((prodotti: Prodotto[]) => {
        this.prodotti = prodotti;

        // recupero i suoi tesseramenti attivi
        this._api.getTesseramentiUtente(utente.id).subscribe((tesseramenti: Tesseramento[]) => {
          this.tesseramenti = tesseramenti;

          setTimeout(() => {
            this.caricamento = false;
          }, 500);

          // controllo che i prodotti siano disponibile e recupero il carrello
          this.getInfo();
          this.getCarrello();

          // ordino i prodotti mostrando prima quelli abilitati
          this.prodotti.sort(function (a, b) { return (b.disabilitato === a.disabilitato) ? 0 : b.disabilitato ? -1 : 1; });

        }, err => {
          this._router.navigate(['/gruppi']);
        })
      }, err => {
        this._router.navigate(['/gruppi']);
      })
    }, err => {
      this._router.navigate(['/gruppi']);
    })
  }

  getInfo() {
    // ciclo tra i prodotti
    for (let prodotto of this.prodotti) {

      // Ciclo tra i tesseramenti del cliente
      for (let tesseramento of this.tesseramenti) {

        // se trovo il match disabilito il prodotto
        if (tesseramento.id_prodotto == prodotto.id) {

          if (prodotto.id_categoria != 13) {
            prodotto.disabilitato = true;
            prodotto.stato = '- (ACQUISTATO E VALIDO)'
            prodotto.attivo = true;
          }          

          // se c'è un prodotto obbligatorio nei tesseramenti attivi abilito il resto dei prodotti
          if (prodotto.obbligatorio) {
            this.disable_all = false;
          }

          // // se è presente la SkiCard della vialattea disabilito il disable skipass
          // if (prodotto.id == 1) {
          //   this.disable_skipass = false;
          // }

        }

      }

    }
  }

  getCarrello() {    

    // recupero carrello
    this._cart.subjectCarrelloGruppo.subscribe(cartData => {

      // controllo se il carrello è presente
      if (cartData) {

        // controllo se il carrello ha più di un prodotto
        if (cartData.carrello.length > 0) {

          // se il carrello è di questo gruppo lo mostro      
          if (this.gruppo?.id == cartData.gruppo) {

            // se presente lo recupero
            this.carrello = cartData;

            this.totale = 0;
            
            // ciclo nel carello per ogni membro
            for (let cart of this.carrello.carrello) {

              // calcolo quanti prodotti ha il cliente selezionato
              this.contaProdotti[cart.cliente.id] = cart.prodotti.length;

              // recupero il totale dei prodotti   
              cart.prodotti.forEach((prod: Prodotto) => (this.totale += prod.prezzo));

              // ciclo nei prodotti dei membri
              for (let cart_product of cart.prodotti) {

                // ciclo tra i prodotti
                for (let prodotto of this.prodotti) {

                  // se trovo il match tra il membro selezionato e il carrello
                  if (this.membroSelezionato?.id == cart.cliente.id) {

                    // seleziono i prodotti che sono presenti nel carrello
                    if (prodotto.id == cart_product.id) {
                      prodotto.selezionato = true;

                      // se è presente un prodotto obbligatorio nel carrello abilito tutto in caso siano disabilitai
                      if (prodotto.obbligatorio) {
                        this.disable_all = false;
                      }

                      // // se è presente la SkiCard della vialattea disabilito il disable skipass
                      // if (prodotto.id == 1) {
                      //   this.disable_skipass = false;
                      // }
                    }


                  }

                }

              }
            }
          } else {

            this.carrello.gruppo = this.gruppo!.id;
            this.carrello.carrello = [];
            this._cart.subjectCarrelloGruppo.next(this.carrello);            
            this.contaProdotti[this.membroSelezionato!.id] = 0;

          }
        }
      }
    })
  }

  onGoBack() {
    this._router.navigate(['/gruppi'])
  }

  aggiungiProdotto(prodotto: Prodotto) {

    // se il prodotto è selezionato lo tolgo dal carrello
    if (!prodotto.selezionato) {

      prodotto.selezionato = true;

      // se il prodotto NON è selezionato lo inserisco nel carrello, ciclo per il carrello
      for (let cart of this.carrello.carrello) {

        // trovo il match tra il membro selezionato e il cliente nel carrello
        if (this.membroSelezionato?.id == cart.cliente.id) {

          // se il prodotto era obbligatorio abilito tutti gli altri prodotti
          if (prodotto.obbligatorio) {
            this.disable_all = false;
          }
          // // se è presente la SkiCard della vialattea disabilito il disable skipass
          // if (prodotto.id == 1) {
          //   this.disable_skipass = false;
          // }

          cart.prodotti.push(prodotto);
          this._cart.subjectCarrelloGruppo.next(this.carrello);          
          this.contaProdotti[cart.cliente.id] = cart.prodotti.length;
          //this._notification.create('success', 'Prodotto aggiunto al carrello', `${prodotto.nome} è stato aggiunto con successo al carrello`, { nzPlacement: 'bottomLeft', nzDuration: 1000 });
          break;
        }
      }


    } else {

      prodotto.selezionato = false;

      // ciclo per il carrello del gruppo
      for (let cart of this.carrello.carrello) {

        // trovo il match tra il membro selezionato e il cliente nel carrello
        if (this.membroSelezionato?.id == cart.cliente.id) {

          // se il prodotto è obbligatorio, quando viene rimosso tolgo tutto
          if (prodotto.obbligatorio) {
            this.disable_all = true;
            cart.prodotti = [];
            this._cart.subjectCarrelloGruppo.next(this.carrello);            

            // recupero gli altri prodotti selezionati
            let prodotti_selezionati = this.prodotti.filter(prodotto => prodotto.selezionato);

            // ciclo per gli altri prodotti selezionati e li deseleziono
            for (let prodotto_selezionato of prodotti_selezionati) {
              prodotto_selezionato.selezionato = false;
            }

            this.contaProdotti[cart.cliente.id] = cart.prodotti.length;
            //this._notification.create('success', 'Prodotti rimossi dal carrello', `È stato rimosso il tesseramento dello SciClub dal carrello, tutti gli altri prodotti sono stati rimossi!`, { nzPlacement: 'bottomLeft', nzDuration: 3000 });
            break;
          // } else if (prodotto.id == 1) {
          //   // this.disable_skipass = true;
          //   let updatedProducts = cart.prodotti.filter((prod: Prodotto) => prod.id_categoria !== 13 && prod.id !== prodotto.id);

          //   cart.prodotti = updatedProducts;
          //   this._cart.subjectCarrelloGruppo.next(this.carrello);            
          //   this.contaProdotti[cart.cliente.id] = cart.prodotti.length;

          //   // recupero gli altri prodotti selezionati
          //   let prodotti_selezionati = this.prodotti.filter(prodotto => prodotto.selezionato);

          //   // ciclo per gli altri prodotti selezionati e li deseleziono
          //   for (let prodotto_selezionato of prodotti_selezionati) {
          //     if (prodotto_selezionato.id_categoria == 13)
          //       prodotto_selezionato.selezionato = false;
          //   }

          } else {
            let updatedProducts = cart.prodotti.filter((prod: Prodotto) => prod.id !== prodotto.id);
            cart.prodotti = updatedProducts;
            this._cart.subjectCarrelloGruppo.next(this.carrello);            
            this.contaProdotti[cart.cliente.id] = cart.prodotti.length;
            //this._notification.create('success', 'Prodotto rimosso dal carrello', `${prodotto.nome} è stato rimosso con successo dal carrello`, { nzPlacement: 'bottomLeft', nzDuration: 1000 });
            break;
          }

        }
      }
    }

    this.totale = 0;
    // calcolo il totale del carrello per tutti i membri
    for (let cart of this.carrello.carrello) {
      cart.prodotti.forEach((prod: Prodotto) => (this.totale += prod.prezzo));
    }
  }

  cassa() {
    
    let updatedCart = this.carrello.carrello.filter((cart: CarrelloMembro) => cart.prodotti.length > 0);
    this.carrello.carrello = updatedCart;    

    this._cart.subjectCarrelloGruppo.next(this.carrello);    
    this._router.navigate(['/gruppi/' + this.gruppo!.id + '/cassa'])
  }
}


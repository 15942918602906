import { Component, OnInit } from '@angular/core';
import { TokenStorageService } from '../services/token-storage.service';
import { AuthUser } from '../shared/interface.model';
import { RedirectService } from '../shared/redirect.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-selezione',
  templateUrl: './selezione.component.html',
  styleUrls: ['./selezione.component.scss']
})
export class SelezioneComponent implements OnInit {

  constructor(public _redirect: RedirectService, private _token: TokenStorageService, private _router: Router) { }

  user?: AuthUser

  ngOnInit(): void {
    // Recupero i dati dell'utente salvati nel sessionStorage    
    this._token.subjectUser.subscribe((user) => {
      this.user = user == undefined ? this._token.getUser() : user;
    })
  }

  scroll(){    
    const element = document.getElementById("prodotti");

    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
    else {
      let h = window.innerHeight;
      let d = h-70;
      window.scrollBy(0, d)
    }
  }

  login(){
    this._router.navigate(['login'])
  }

}

import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NzUploadFile } from 'ng-zorro-antd/upload';
import { ApiService } from '../services/api.service';
import { CartService } from '../services/cart.service';
import { TokenStorageService } from '../services/token-storage.service';
import { AuthUser, Carrello, CodiceSconto, Prodotto, PuntoRitiro, Utente } from '../shared/interface.model';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { differenceInCalendarDays } from 'date-fns';
import { DatePipe } from '@angular/common';
import { Location } from '@angular/common';
import { NzFormTooltipIcon } from 'ng-zorro-antd/form';

@Component({
  selector: 'app-cassa',
  templateUrl: './cassa.component.html',
  styleUrls: ['./cassa.component.scss']
})
export class CassaComponent implements OnInit {

  constructor(private _token: TokenStorageService, private _cart: CartService, private _api: ApiService, private _router: Router, private _location: Location, private _datePipe: DatePipe, private _notification: NzNotificationService) { }

  step: number = 0;

  loading: boolean = false;
  caricamento: boolean = false;
  error_image: boolean = false;
  gratis: boolean = false;

  user?: AuthUser;
  utente!: Utente;

  img_profilo: string = '';
  immagine_utente: string = '';
  fileList: NzUploadFile[] = [];

  giorni: string[] = ['Lunedì', 'Martedì', 'Mercoledì', 'Giovedì', 'Venerdì', 'Sabato', 'Domenica'];

  codici_sconto: CodiceSconto[] = [];
  punti_ritiro: PuntoRitiro[] = [];
  carrello: Carrello = { prodotti: [], totale: 0, sub_totale: 0, commissioni: 0 };
  informazione: { ritiro: boolean, assicurativi: Prodotto[], immagine: boolean, giornalieri: Prodotto[], giornalieri_specifici: Prodotto[], skipass: boolean, codice_fiscale: boolean, codici: Prodotto[] } = { ritiro: false, assicurativi: [], immagine: false, giornalieri: [], giornalieri_specifici: [], skipass: false, codice_fiscale: false, codici: [] }
  metodo = { bonifico: true, carta: true, satispay: true, dopo: true };

  captchaTooltipIcon: NzFormTooltipIcon = {
    type: 'info-circle',
    theme: 'twotone'
  };

  formUtente!: FormGroup;
  formInformazioni!: FormGroup;
  formMetodo!: FormGroup;
  formCodiceSconto!: FormGroup;


  ngOnInit(): void {

    this.codici_sconto = [];

    // Formo i FormGruop con i loro dati
    this.formInformazioni = new FormGroup({
      ritiro: new FormControl(null, Validators.required),
      immagine: new FormControl(null, Validators.required)
    });

    this.formUtente = new FormGroup({
      nome: new FormControl(null),
      cognome: new FormControl(null),
      data_nascita: new FormControl(null),
      email: new FormControl(null),
      codice_fiscale: new FormControl(null),
    })

    this.formMetodo = new FormGroup({
      metodo: new FormControl(null, Validators.required)
    });

    this.formCodiceSconto = new FormGroup({
      codice: new FormControl(null, Validators.required)
    });

    // Recupero l'utente
    this.user = this._token.getUser();

    // Recupero i punti di ritiro
    this._api.getPuntiRitiro().subscribe(data => {
      this.punti_ritiro = data;

      // Recupero i dati dell'utente
      this._api.getUtente(this.user!.id).subscribe(utente => {
        this.utente = utente;

        this.img_profilo = utente.immagine_file.length > 0 ? utente.immagine_file : '../../assets/img/avatar.jpg';
        this.immagine_utente = utente.immagine;

        // Salvo i dati nel form e lo disabilito
        this.formUtente.patchValue(this.utente);
        this.formUtente.disable();

        // Recupero il carrello
        this._cart.subjectCarrello.subscribe((cartData) => {

          this.carrello = cartData;

          // Se non ho prodotti torno indietro
          if (this.carrello.prodotti.length <= 0) {
            this._router.navigate(['/'])
          }

          this.stepZero(this.step);
          this.getMetodo();

        });

        this._cart.subjectCodiciSconto.subscribe((codici_sconto) => {
          this.codici_sconto = codici_sconto;
          this.carrello.sub_totale = this._cart.getCartSubTotal();
          this.gratis = this._cart.gratis;
        })


      }, err => {
        this._notification.create('error', 'Errore', err, { nzPlacement: 'bottomLeft', nzDuration: 5000 });
      })
    }, err => {
      this._notification.create('error', 'Errore', err, { nzPlacement: 'bottomLeft', nzDuration: 5000 });
    })

  }

  beforeUpload = (file: any): boolean => {

    this.fileList = this.fileList.concat(file);

    // Controllo se il file caricato è un'immagine
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';

    // Se è un immagine procedo
    if (isJpgOrPng) {

      const reader = new FileReader();
      reader.onload = () => {

        // Setto come false le variabili
        this.error_image = false;
        this.informazione.immagine = false
        this.formInformazioni.patchValue({ immagine: file.name });

        // Salvo il nome e il base 64 del file caricato        
        this.immagine_utente = file.name;
        this.img_profilo = reader.result as string;
      }
      reader.readAsDataURL(file);

      return false;
    } else {

      this.error_image = true;
      this.fileList = [];
    }

    return false;
  };

  removeUpload = (file: any): boolean => {
    this.immagine_utente = '';
    this.img_profilo = '../../assets/img/avatar.jpg';
    this.formInformazioni.patchValue({ immagine: '' });
    this.fileList = [];
    return false;
  }

  onRimuoviProdotto(id: number): void {
    this._cart.removeProduct(id);

    let prodotti = this.carrello.prodotti.filter((prodotto: Prodotto) => prodotto.informativo)
    if (prodotti.length <= 0)
      this.formInformazioni.patchValue({ ritiro: null })

  }

  onRimuoviImmagine() {

    // Se l'immagine dell'utente è salvata sul server        
    if (this.utente.immagine_file.length > 0) {

      // Elimino il file salvato
      this._api.deleteImmagineUtente(this.utente.id).subscribe(data => {

        this.img_profilo = '../../assets/img/avatar.jpg';

      }, err => {
        this._notification.create('error', 'Errore', err, { nzPlacement: 'bottomLeft', nzDuration: 5000 });
      })
    } else {

      // Elimino il file presente nel file upload
      this.fileList = [];
      this.img_profilo = '../../assets/img/avatar.jpg';
      this.formInformazioni.patchValue({ immagine: '' });
      this.stepZero(0)

    }
  }

  onRimuoviCodiceSconto(id: number): void {

    this._cart.removeCodiceSconto(id);

  }

  getMetodo() {

    // Ciclo per i prodotti nel carrello
    for (let prodotto of this.carrello.prodotti) {

      // Se c'è un prodotto della categoria skipass disabilito bonifico
      if (prodotto.id_categoria == 2) {
        this.metodo.bonifico = false;
      }
      // Se non è presente il flag per i bonifici disabilito il metodo
      if (!prodotto.bonifico) {
        this.metodo.bonifico = false;
      }
      // Se non è presente il flag per la carta disabilito il metodo
      if (!prodotto.carta) {
        this.metodo.carta = false;
      }
      // Se non è presente il flag per satispay disabilito il metodo
      if (!prodotto.satispay) {
        this.metodo.satispay = false;
      }

      if (prodotto.obbligatorio) {
        this.metodo.bonifico = false;
        this.metodo.dopo = false;
      }
    }

  }

  stepZero(step: number) {

    this.informazione.giornalieri = [];
    this.informazione.giornalieri_specifici = [];
    this.informazione.assicurativi = [];
    this.informazione.codici = [];

    for (const controlName in this.formInformazioni.controls) {
      if (controlName != 'ritiro' && controlName != 'immagine') {
        this.formInformazioni.removeControl(controlName);
      }
    }

    let tot = 0;

    // Ciclo tra i prodotti nel carrello
    for (let prodotto of this.carrello.prodotti) {

      // Se c'è un prodotto con il flag informativo mostro i punti di ritiro
      if (prodotto.informativo) {
        this.informazione.ritiro = true;
      }

      // Se c'è un prodotto con il flag assicurativo mostro le condizioni dell'assicurazione
      if (prodotto.assicurativo) {

        if (!this.utente.codice_fiscale || this.utente.codice_fiscale.length <= 0)
          this.informazione.codice_fiscale = true;

        // aggiungo ai prodotti assicurativi da scegliere se non è già presente
        const alreadyExists = this.informazione.assicurativi.find((assicurazione) => assicurazione.id === prodotto.id);
        if (!alreadyExists) { this.informazione.assicurativi.push(prodotto); }

        // rendo obbligatoria la scelta
        this.formInformazioni.addControl('assicurazione_' + prodotto.id, new FormControl(null, Validators.requiredTrue));
      }

      // // Se c'è un prodotto con il flag codice mostro il form per l'inserimento del codice
      // if (prodotto.codice) {        

      //   // aggiungo ai prodotti assicurativi da scegliere se non è già presente
      //   const alreadyExists = this.informazione.codici.find((codice) => codice.id === prodotto.id);
      //   if (!alreadyExists) { this.informazione.codici.push(prodotto); }

      //   // rendo obbligatoria la scelta
      //   this.formInformazioni.addControl('codici_' + prodotto.id, new FormControl(null, Validators.required));
      // }

      // Se c'è un prodotto con il flag fototessera mostro il messaggio di errore
      if (prodotto.fototessera && this.img_profilo == '../../assets/img/avatar.jpg') {
        this.informazione.immagine = true;
      }

      // Se c'è un prodotto della categoria skipass disabilito bonifico e paga in seguito
      if (prodotto.id_categoria == 2) {
        this.informazione.skipass = true;
      }

      // se è un prodotto giornaliero 
      if (prodotto.giornaliero) {

        // parso i giorni se non sono già stati parsati
        try {
          prodotto.giorni = JSON.parse(prodotto.giorni);
        } catch {
          prodotto.giorni = prodotto.giorni;
        }

        // aggiungo ai prodotti giornalieri da scegliere se non è già presente
        const alreadyExists = this.informazione.giornalieri.find((giornaliero) => giornaliero.id === prodotto.id);
        if (!alreadyExists) { this.informazione.giornalieri.push(prodotto); }

        // rendo obbligatoria la scelta
        this.formInformazioni.addControl('giornaliero_' + prodotto.id, new FormControl('', Validators.required));
      }

      // se è un prodotto ha delle date specifiche
      if (prodotto.date_specifiche) {

        // aggiungo ai prodotti giornalieri da scegliere se non è già presente
        const alreadyExists = this.informazione.giornalieri_specifici.find((giornaliero) => giornaliero.id === prodotto.id);
        if (!alreadyExists) { this.informazione.giornalieri_specifici.push(prodotto); }

        // rendo obbligatoria la scelta
        this.formInformazioni.addControl('giornaliero_specifico_' + prodotto.id, new FormControl('', Validators.required));
      }

      if (!prodotto.giornaliero && !prodotto.date_specifiche)
        tot += prodotto.prezzo

    }

    // this.carrello.commissioni = (tot / 100) * 2;
    // this.carrello.totale = this._cart.getCartTotal();

    // Pulisco i validator se non è richiesto
    if (!this.informazione.ritiro) {
      this.formInformazioni.get('ritiro')?.clearValidators();
      this.formInformazioni.get('ritiro')?.updateValueAndValidity();
    }

    // Pulisco i validator se non è richiesto
    if (!this.informazione.immagine) {
      this.formInformazioni.get('immagine')?.clearValidators();
      this.formInformazioni.get('immagine')?.updateValueAndValidity();
    }

    // Se non ho informazioni da richiedere vado alla scelta del metodo
    if (!this.informazione.ritiro && !this.informazione.immagine && this.informazione.assicurativi.length <= 0 && this.informazione.giornalieri.length <= 0 && this.informazione.giornalieri_specifici.length <= 0 && !this.informazione.codice_fiscale) {
      this.step = step != 0 ? step : 1;
    } else {
      this.step = step;
    }
  }

  // Funzione per disabilitare specifici giorni con un parametro personalizzato
  onDisableDateGiornalieri(giorni: any, inizio_validita: any, fine_validita: any) {
    return (current: Date): boolean => {

      // Formo le date di inizio e fine
      let date_inizio = new Date(inizio_validita)
      date_inizio.setHours(0, 0, 0)

      let date_fine = new Date(fine_validita)
      date_fine.setHours(23, 59, 59)

      // Mostro le date disponibili in base alle date di inizio e fine validità
      if (date_inizio <= current && date_fine >= current) {

        let date_08 = new Date();
        date_08.setHours(8, 0, 0);
        let oggi = new Date()

        if (differenceInCalendarDays(current, oggi) <= 0) {
          if (date_08.getTime() >= oggi.getTime()) {
            // Recupero i giorni del prodotto e mostro solo quelli
            const day = current.getDay();
            const numeriConvertiti = this.convertiArrayNomiInNumeri(giorni);
            return !numeriConvertiti.includes(day);
          } else {
            return true;
          }
        } else {
          // Recupero i giorni del prodotto e mostro solo quelli
          const day = current.getDay();
          const numeriConvertiti = this.convertiArrayNomiInNumeri(giorni);
          return !numeriConvertiti.includes(day);
        }
      } else
        return true;
    };
  }

  // Funzione per disabilitare specifici giorni con un parametro personalizzato
  onDisableDateGiornalieriSpecifici(date_specifiche_dal: any, date_specifiche_al: any) {
    return (current: Date): boolean => {

      // Formo le date di inizio e fine
      let date_inizio = new Date(date_specifiche_dal)
      date_inizio.setHours(0, 0, 0)

      let date_fine = new Date(date_specifiche_al)
      date_fine.setHours(23, 59, 59)

      // Mostro le date disponibili in base alle date di inizio e fine
      if (date_inizio <= current && date_fine >= current) {

        // Mostro solo le date maggiori rispetto alla data attuale

        let date_08 = new Date();
        date_08.setHours(8, 0, 0);
        let oggi = new Date()

        if (differenceInCalendarDays(current, oggi) <= 0) {
          if (date_08.getTime() >= oggi.getTime()) {
            return false
          } else {
            return true;
          }
        } else
          return false;
      } else
        return true;
    };
  }

  convertiArrayNomiInNumeri(nomiGiorni: string[]): number[] {
    const numeriGiorni: number[] = [];

    for (const nomeGiorno of nomiGiorni) {
      let indice = this.giorni.indexOf(nomeGiorno) + 1;
      if (indice !== -1) {
        if (indice == 7)
          indice = 0;
        numeriGiorni.push(indice);
      }
    }

    return numeriGiorni;
  }

  onPdf(id: number) {
    this._api.getPDFAssicurazione(id).subscribe(data => {
      let downloadURL = window.URL.createObjectURL(data);
      let tab = window.open();
      tab!.location.href = downloadURL;
    }, err => {
      this._notification.create('error', 'Errore', err, { nzPlacement: 'bottomLeft', nzDuration: 5000 });
    })
  }

  onGoBack() {
    this._location.back();
  }

  onProssimoStep() {
    window.scrollTo(0, 0);
    this.onInfo();
    this.step++;
  }

  onInfo() {

    //ciclo nei prodotti
    for (let prodotto of this.carrello.prodotti) {

      // ciclo nei giornalieri
      for (let giornaliero of this.informazione.giornalieri) {

        // se trovo il match
        if (prodotto.id == giornaliero.id) {

          let giorno = this.formInformazioni.get('giornaliero_' + prodotto.id)?.value;
          prodotto.data_utilizzo = this._datePipe.transform(giorno, 'yyyy-MM-dd');

          let date_21_1 = new Date();
          date_21_1.setHours(21, 1, 0);
          let date_23 = new Date();
          date_23.setHours(23, 0, 0);
          let date_23_1 = new Date();
          date_23_1.setHours(23, 1, 0);
          let date_08 = new Date();
          date_08.setHours(8, 0, 0);

          let oggi = new Date()

          if (differenceInCalendarDays(new Date(giorno), oggi) == 1 && prodotto.id_categoria == 13) {
            date_08.setDate(date_08.getDate() + 1);

            if (oggi >= date_21_1 && oggi <= date_23) {
              prodotto.supplemento = 0.50;
            } else if (oggi >= date_23_1 && oggi <= date_08) {
              prodotto.supplemento = 2.00
            }
          } else if (differenceInCalendarDays(new Date(giorno), oggi) == 0 && prodotto.id_categoria == 13) {
            date_23_1.setDate(date_23_1.getDate() - 1);
            if (oggi >= date_23_1 && oggi <= date_08) {
              prodotto.supplemento = 2.00;
            }
          } else {
            prodotto.supplemento = 0.00
          }

        }
      }

      // ciclo nei giornalieri specifici
      for (let giornaliero of this.informazione.giornalieri_specifici) {

        // se trovo il match
        if (prodotto.id == giornaliero.id) {

          let giorno = this.formInformazioni.get('giornaliero_specifico_' + prodotto.id)?.value;
          prodotto.data_utilizzo = this._datePipe.transform(giorno, 'yyyy-MM-dd');

          let date_21_1 = new Date();
          date_21_1.setHours(21, 1, 0);
          let date_23 = new Date();
          date_23.setHours(23, 0, 0);
          let date_23_1 = new Date();
          date_23_1.setHours(23, 1, 0);
          let date_08 = new Date();
          date_08.setHours(8, 0, 0);

          let oggi = new Date()

          if (differenceInCalendarDays(new Date(giorno), oggi) == 1 && prodotto.id_categoria == 13) {
            date_08.setDate(date_08.getDate() + 1);

            if (oggi >= date_21_1 && oggi <= date_23) {
              prodotto.supplemento = 0.50;
            } else if (oggi >= date_23_1 && oggi <= date_08) {
              prodotto.supplemento = 2.00
            }
          } else if (differenceInCalendarDays(new Date(giorno), oggi) == 0 && prodotto.id_categoria == 13) {
            date_23_1.setDate(date_23_1.getDate() - 1);
            if (oggi >= date_23_1 && oggi <= date_08) {
              prodotto.supplemento = 2.00;
            }
          } else {
            prodotto.supplemento = 0.00
          }

        }
      }
    }

    this.carrello.totale = this._cart.getCartTotal()
  }

  onCoupon() {
    const codice = this.formCodiceSconto.get('codice')?.value || ''

    // Recupero i dati del codice sconto per l'utente
    this._api.getCodiceScontoUtente(codice.trim(), this.user!.id).subscribe(codice_sconto => {

      // Azzero il form e recupero il subtotale e controllo se l'ordine è gratis
      this.formCodiceSconto.setValue({ codice: null })
      this._cart.addCodiceSconto(codice_sconto);

    }, err => {
      this._notification.create('error', 'Errore', err, { nzPlacement: 'bottomLeft', nzDuration: 5000 });
    })
  }

  onPagamentoInSeguito() {

    this.caricamento = true;

    let carrello = [];

    // recupero i dati del cliente
    let cliente = {
      id: this.utente.id,
      nome: this.utente.nome,
      cognome: this.utente.cognome,
      email: this.utente.email,
      telefono: this.utente.telefono,
      codice_fiscale: this.utente.codice_fiscale,
      immagine: this.immagine_utente ? this.immagine_utente : this.utente.immagine,
      immagine_file: this.img_profilo != '../../assets/img/avatar.jpg' ? this.img_profilo : ''
    }

    // formo il carrello con il cliente
    carrello.push({ cliente: cliente, prodotti: this.carrello.prodotti })

    // recupero il metodo di pagamento
    let metodo = !this.gratis ? this.formMetodo.get('metodo')?.value : "Coupon";

    // formo l'ordine inserendo i dati inseriti dal cliente
    let ordine = {
      metodo: metodo || '',
      nota: this.formInformazioni.get('ritiro')?.value ? 'Ritiro presso: ' + this.formInformazioni.get('ritiro')?.value : '',
      eseguitoDa: this.user?.cognome + ' ' + this.user!.nome,
      id_gruppo: null,
      gruppo: null,
      carrello: carrello,
      codici_sconto: this.gratis ? this.codici_sconto : [],
      token: null,
      stato: this.gratis ? 'pagato' : 'non pagato'
    }

    this._api.addOrdine(ordine).subscribe(data => {
      if (data.code == 200) {
        this.caricamento = false;
        this._cart.clearCart();

        if (this.gratis)
          this._router.navigate(['/esito-pagamento-dopo'], { queryParams: { ordine: true } });
        else
          this._router.navigate(['/esito-pagamento-dopo']);

      } else if (data.code == 300) {
        this.caricamento = false;
        this._cart.clearCart();

        if (this.gratis)
          this._router.navigate(['/esito-pagamento-dopo'], { queryParams: { ordine: true } });
        else
          this._router.navigate(['/esito-pagamento-dopo']);

      }
    }, err => {
      this.caricamento = false;
      this._notification.create('error', 'Errore', err, { nzPlacement: 'bottomLeft', nzDuration: 5000 });
    });

  }

  onPagamento() {
    this.caricamento = true;

    // recupero il metodo di pagamento
    let metodo = !this.gratis ? this.formMetodo.get('metodo')?.value : "Coupon";

    let carrello = [];

    // recupero i dati del cliente
    let cliente = {
      id: this.utente.id,
      nome: this.utente.nome,
      cognome: this.utente.cognome,
      email: this.utente.email,
      telefono: this.utente.telefono,
      codice_fiscale: this.utente.codice_fiscale,
      immagine: this.immagine_utente ? this.immagine_utente : this.utente.immagine,
      immagine_file: this.img_profilo != '../../assets/img/avatar.jpg' ? this.img_profilo : ''
    }

    // formo il carrello con il cliente
    carrello.push({ cliente: cliente, prodotti: this.carrello.prodotti })

    // formo l'ordine inserendo i dati inseriti dal cliente
    let ordine = {
      metodo: metodo,
      note: this.formInformazioni.get('ritiro')?.value ? 'Ritiro presso: ' + this.formInformazioni.get('ritiro')?.value : '',
      eseguitoDa: this.user?.cognome + ' ' + this.user!.nome,
      carrello: carrello,
      codici_sconto: this.codici_sconto
    }

    if (metodo === 'Bonifico') {
      this.onPagamentoInSeguito();
    } else if (metodo === 'Dopo') {
      this.onPagamentoInSeguito();
    } else if (metodo === 'Coupon') {
      this.onPagamentoInSeguito();
    } else if (metodo == 'Carta di credito') {
      // in base al metodo selezionato recupero l'url dove deve essere reinderizzato il cliente
      this._api.getPagamentoCarta(ordine).subscribe(data => {
        localStorage.setItem('token_pagamento', data.token_pagamento);
        window.location.href = data.url;
        this.caricamento = false;
      }, err => {
        this.caricamento = false;
        this._notification.create('error', 'Errore', err, { nzPlacement: 'bottomLeft', nzDuration: 5000 });
      })

    } else if (metodo == 'Satispay') {
      // in base al metodo selezionato recupero l'url dove deve essere reinderizzato il cliente
      this._api.getPagamentoSatispay(ordine).subscribe(data => {
        localStorage.setItem('satispay', data.satispay);
        window.location.href = data.url;

        this.caricamento = false;
      }, err => {
        this.caricamento = false;
        this._notification.create('error', 'Errore', err, { nzPlacement: 'bottomLeft', nzDuration: 5000 });
      })

    }

  }
}

import { DatePipe, Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NzUploadFile } from 'ng-zorro-antd/upload';
import { ApiService } from 'src/app/services/api.service';
import { TokenStorageService } from 'src/app/services/token-storage.service';
import { AuthUser, CodiceSconto, Contatto, Utente } from 'src/app/shared/interface.model';
import { CartService } from '../services/cart.service';
import { NzNotificationService } from 'ng-zorro-antd/notification';

@Component({
  selector: 'app-account',
  templateUrl: './account.component.html',
  styleUrls: ['./account.component.scss']
})
export class AccountComponent implements OnInit {

  public disabledDate = (current: Date): boolean => current > new Date();

  alertMessage = '';
  alertType: "success" | "info" | "warning" | "error" = 'success';
  alertVisible: boolean = false;
  loading: boolean = false;
  passwordOldVisible: boolean = false;
  passwordNewVisible: boolean = false;
  passwordConfirmVisible: boolean = false;

  user?: AuthUser;
  utente?: Utente;
  contatti: Contatto[] = [];
  codici_sconto: CodiceSconto[] = [];

  file_immagine_utente!: string;
  immagine_utente!: string;
  fileList: NzUploadFile[] = [];

  propic: string = '../../assets/img/avatar.jpg';
  ricerca_contatti: string = '';
  ricerca_codice: string = '';

  expandSet = new Set<number>();

  formUtente!: FormGroup;
  formPassword!: FormGroup;
  formContatti!: FormGroup;

  constructor(private _api: ApiService, private _token: TokenStorageService, private _datePipe: DatePipe, private _cart: CartService, private _router: Router, private _location: Location, private _notification: NzNotificationService) { }

  ngOnInit(): void {

    this.formUtente = new FormGroup({
      nome: new FormControl(null, Validators.required),
      cognome: new FormControl(null, Validators.required),
      email: new FormControl(null, Validators.required),
      data_nascita: new FormControl(null, Validators.required),
      comune_nascita: new FormControl(null, Validators.required),
      provincia_nascita: new FormControl(null, Validators.required),
      nazione_nascita: new FormControl(null, Validators.required),
      indirizzo: new FormControl(null, Validators.required),
      comune: new FormControl(null),
      provincia: new FormControl(null),
      nazione: new FormControl(null),
      codice_fiscale: new FormControl(null, Validators.required),
      telefono: new FormControl(null),
      newsletter: new FormControl(null)
    })

    this.formPassword = new FormGroup({
      old_password: new FormControl(null, Validators.required),
      new_password: new FormControl(null, Validators.required),
      confirm_password: new FormControl(null, Validators.required),
    })

    this.formContatti = new FormGroup({
      nome: new FormControl(null, Validators.required),
      cognome: new FormControl(null, Validators.required),
      telefono: new FormControl(null),
      email: new FormControl(null),
      parentela: new FormControl(null)
    })

    this.getData();
  }

  getData() {

    // Recupero l'utente loggato
    this.user = this._token.getUser();

    // Recupero tutti i dati dell'utente loggato
    this._api.getUtente(this.user!.id).subscribe((utente: Utente) => {

      this.utente = utente;
      this.propic = '../../assets/img/avatar.jpg';
      this.propic = this.utente.immagine_file.length > 0 ? this.utente.immagine_file : this.propic;

      // Recupero i contatti dell'utente
      this._api.getContattiUtente(this.user!.id).subscribe((contatti: Contatto[]) => {
        this.contatti = contatti;

        this._api.getCodiciScontoUtente(this.user!.id).subscribe((codici_sconto: CodiceSconto[]) => {
          this.codici_sconto = codici_sconto;

          // Aggiorno il form con i dati che ho recuperati
          this.formUtente.setValue({
            nome: utente.nome,
            cognome: utente.cognome,
            email: utente.email,
            data_nascita: utente.data_nascita,
            comune_nascita: utente.comune_nascita,
            provincia_nascita: utente.provincia_nascita,
            nazione_nascita: utente.nazione_nascita,
            indirizzo: utente.indirizzo,
            comune: utente.comune,
            provincia: utente.provincia,
            nazione: utente.nazione,
            codice_fiscale: utente.codice_fiscale,
            telefono: utente.telefono,
            newsletter: utente.newsletter ? true : false,
          })
        }, err => {
          this._router.navigate(['/'])
        })
      }, err => {
        this._router.navigate(['/'])
      })

    }, err => {
      this._router.navigate(['/'])
    })

  }

  onOrdinamentoContatto(a: Contatto, b: Contatto) {
    return a.cognome.localeCompare(b.cognome)
  }

  onOrdinamentoParentela(a: Contatto, b: Contatto) {
    return (a.parentela || '').localeCompare((b.parentela || ''))
  }

  onOrdinamentoCodice(a: CodiceSconto, b: CodiceSconto) {
    return a.codice.localeCompare(b.codice)
  }

  onOrdinamentoValore(a: CodiceSconto, b: CodiceSconto) {
    return (a.valore -  b.valore)
  }

  deleteUtente() {

    // Elimino l'utente
    this._api.deleteUtente(this.user!.id).subscribe(data => {

      // Elimino i dati nello storage e ricarico la pagina
      this._token.clearStorage();
      this._cart.clearCart();
      this._cart.clearCartGruppo();
      this._router.navigate(['/home']);

    }, err => {
      this._notification.create('error', 'Errore', err, { nzPlacement: 'bottomLeft', nzDuration: 5000 });
    })
  }

  deleteImmagine() {

    // Elimino l'immagine dal server
    this._api.deleteImmagineUtente(this.user!.id).subscribe(data => {

      // Rimetto l'immagine di base
      this.propic = '../../assets/img/avatar.jpg';
      this.utente!.immagine_file = '';
      this.utente!.immagine = '';

    }, err => {
      this._notification.create('error', 'Errore', err, { nzPlacement: 'bottomLeft', nzDuration: 5000 });
    })
  }

  deleteContatto(id_contatto: number) {

    // Elimino il contatto dal db
    this._api.deleteContatto(id_contatto).subscribe(data => {

      // Ricarico i dati
      this.getData();

    }, err => {
      this._notification.create('error', 'Errore', err, { nzPlacement: 'bottomLeft', nzDuration: 5000 });
    })
  }

  beforeUpload = (file: any): boolean => {

    // Recupero il file caricato e l'aggiungo all'array dei file
    this.fileList = this.fileList.concat(file);

    // Controllo se il file inserito è di tipo immagine
    const isJpgOrPng = this.fileList.length > 0 ? this.fileList[0]!.type === 'image/jpeg' || this.fileList[0]!.type === 'image/png' : true;
    if (!isJpgOrPng) {

      // Se non è un'immagine mostro l'errore 
      this.onResult("L'immagine profilo deve essere di tipo JPG, PNG o JPEG", 'error', true);
      return false;
    } else {

      // Salvo il base64 e il nome del file
      const reader = new FileReader();
      reader.onload = () => {
        this.immagine_utente = file.name;
        this.file_immagine_utente = reader.result as string;
      }
      reader.readAsDataURL(file);

      return false;
    }
  };

  onResult(message: string, type: any, visible: boolean) {

    // Se richiamate la funzione vado all'inizio della pagina e mostro il messaggio dell'alert
    window.scrollTo(0, 0);
    this.alertMessage = message;
    this.alertVisible = visible;
    this.alertType = type;
    this.loading = false;
  }

  onGoBack() {

    // Torno indietro alla route precedente
    this._location.back();
  }

  onPassword() {

    // Formo l'oggetto con i dati del form
    let password = {
      old_password: this.formPassword.get('old_password')?.value,
      new_password: this.formPassword.get('new_password')?.value,
      confirm_password: this.formPassword.get('confirm_password')?.value
    }

    // Aggiorno la password dell'utente
    this._api.updatePasswordUtente(this.user!.id, password).subscribe(data => {

      // Ricarico i dati e mostro l'alert
      this.getData();
      this.onResult('Password modificata con successo!', 'success', true);

    }, err => {
      this.onResult(err, 'error', true);
    })

  }

  onAddContatto() {

    // Formo l'oggetto con i dati del form
    let contatto = {
      id_cliente: this.user!.id.toString(),
      nome: this.formContatti.get('nome')?.value,
      cognome: this.formContatti.get('cognome')?.value,
      email: this.formContatti.get('email')?.value,
      telefono: this.formContatti.get('telefono')?.value,
      parentela: this.formContatti.get('parentela')?.value
    }

    // Aggiungo il contatto al cliente
    this._api.addContatto(contatto).subscribe(data => {

      // Ricarico i dati e mostro l'alert
      this.getData();
      this.onResult('Contatto aggiunto con successo!', 'success', true);

      // Cancello i dati dal form
      this.formContatti.reset();

    }, err => {
      this.onResult(err, 'error', true);
    })
  }

  onUpdateContatto(contatto: Contatto) {

    // Aggiorno il contatto con i dati aggiornati
    this._api.updateContatto(contatto.id, contatto).subscribe(data => {

      // Ricarico i dati e mostro l'alert
      this.getData();
      this.onResult('Contatto modificato con successo!', 'success', true);

    }, err => {
      this.onResult(err, 'error', true);
    })
  }

  onExpandChange(id: number, checked: boolean): void {

    if (checked) {
      this.expandSet.add(id);
    } else {
      this.expandSet.delete(id);
    }
  }

  async onSubmit() {

    // Formo l'oggetto con i dati del form
    let cliente = {
      nome: this.formUtente.get('nome')?.value,
      cognome: this.formUtente.get('cognome')?.value,
      email: this.formUtente.get('email')?.value,
      data_nascita: this._datePipe.transform(this.formUtente.get('data_nascita')?.value, 'yyyy-MM-dd') || '',
      comune_nascita: this.formUtente.get('comune_nascita')?.value,
      provincia_nascita: this.formUtente.get('provincia_nascita')?.value,
      nazione_nascita: this.formUtente.get('nazione_nascita')?.value,
      indirizzo: this.formUtente.get('indirizzo')?.value,
      comune: this.formUtente.get('comune')?.value,
      provincia: this.formUtente.get('provincia')?.value,
      nazione: this.formUtente.get('nazione')?.value,
      codice_fiscale: this.formUtente.get('codice_fiscale')?.value,
      telefono: this.formUtente.get('telefono')?.value,
      immagine: this.immagine_utente || '',
      immagine_file: this.file_immagine_utente || '',
      newsletter: this.formUtente.get('newsletter')?.value || false,
    }

    // Calcolo l'età in base all'età inserita
    let differenza = Math.abs(Date.now() - new Date(cliente.data_nascita).getTime());
    let eta = Math.floor((differenza / (1000 * 3600 * 24)) / 365.25);

    // Aggiorno i dati dell'utente
    this._api.updateUtente(this.user!.id, cliente).subscribe(async data => {

      // Salvo i dati nello storage
      this._token.setUser({ id: this.user!.id, nome: cliente.nome, cognome: cliente.cognome, email: cliente.email, eta: eta });
      this.getData();

      // Mostro l'alert
      this.onResult('Dati modificati con successo!', 'success', true);
    }, err => {
      this.onResult(err, 'error', true);
    })
  }
}

<div class="container">
    <h2>Skicard VIALATTEA: Cos’è e come funziona !!!</h2>

    <div class="row">
        <div class="col text-center">
            <img src="../../../assets/img/ski_card_vialattea.jpeg" alt="Ski card Vialattea" class="text-center my-5">
        </div>
    </div>    

    <h4 class="text-center" >SKI CARD VIALATTEA…SCIA MANI LIBERE !!!</h4>
    <h5 class="text-center" >USUFRUISCI DELLA COMODITA’ DELLA RICARICA ONLINE</h5>

    <p>La Skicard Vialattea è un supporto magnetico sul quale vengono ricaricati gli skipass a prezzo agevolato per sciare nel comprensorio sciistico della Vialattea. <br /> Una volta entrati in possesso, potrete <strong style="font-style: oblique;">ricaricare online sul nostro sito il vostro skipass a prezzo scontato</strong> e non dovrete più fare la coda alle casse. Basterà recarsi direttamente ai tornelli degli impianti con la skicard in tasca.</p>

    <div class="row my-3">
        <div class="col text-center">
            <a href="../../../assets/pdf/vialattea_sconti.pdf" style="color: red"><h5>Clicca qui per scoprire le nuove offerte per la Stagione sciistica</h5></a>
        </div>
    </div>
    
    <h4>Importante</h4>
    <p>Per usufruire delle nostre offerte <strong>è necessario essere in possesso di una SKICARD VIALATTEA registrata tramite Sci Club Bussoleno</strong>, una volta richiesta è valevole per più anni.</p>

    <h4>Quanto costa?</h4>
    <h5 style="color: red; font-weight: bold">10,00 €</h5>
    <p>Ha validità pluriennale (sarà solo necessario riattivarla all’inizio di ogni stagione semplicemente rinnovando il tesseramento allo sci club Bussoleno)</p>

    <h4>Come posso acquistarla?</h4>
    <ul style="list-style: decimal; padding: 10px 20px;">
        <li>Online tramite il nostro sito</li>
        <li>Presso la nostra sede sociale</li>
    </ul>

    <h4 class="mt-3">RICORDA!</h4>
    <p>Per l’emissione è necessario farci avere una <strong>FOTOGRAFIA</strong> (via e-mail in formato .jpg o direttamente portandola in sede) e indicarci uno dei luoghi di ritiro tra quelli sotto riportati, selezionandolo al momento del pagamento.</p>

    <h4>Dove e quando la ritiro?</h4>
    <p>Quando acquisti online la Skicard Vialattea, nell’ultima fase di acquisto è necessario gentilmente <strong>indicare LUOGO DI RITIRO  all’interno della sezione spedizioni</strong> (sezione Informazioni Aggiuntive): </p>
    <ul style="padding: 0px 20px; list-style: circle">
        <li *ngFor="let punto_ritiro of punti_ritiro">{{punto_ritiro.descrizione}}</li>        
    </ul>

    <h4 class="mt-3">Tempi di consegna</h4>
    <p>La skicard Vialattea potranno essere ritirata presso il punto di ritiro da voi indicato (in orari di apertura), dopo 72/96 ore dall’acquisto regolare con invio della fotografia (come richiesto) oppure dopo una conferma via email da parte dello Sci Club Bussoleno.</p>

    <h4>Come si ricarica?</h4>
    <p>La ricarica online avviene acquistando sul nostro portale il prodotto skipass che ti interessa; <br/> dovrai indicare in fase di acquisto il tuo cognome e nome, data di nascita e la data in cui vorrai sciare. <strong style="color: red">ATTENZIONE!!</strong> La ricarica potrà essere fatta a prezzo scontato <strong>entro le ore 20:00 del giorno precedente</strong>, oppure tra le 20:01 e le 23:00 del giorno precedente con un supplemento di 0.50 €, oppure tra le 23:01 del giorno precedente e le 8:00 del giorno stesso con un supplemento di 2 € .</p>
    <p>Vi siete dimenticati di ricaricare la skicard? <br/> Nessun problema, potrete presentarla alle casse insieme ad un documento di identità, e usufruire comunque di uno sconto come previsto dal nostro planning offerte.</p>

    <h4 class="text-center">HAI PERSO LA TUA SKICARD VIALATTEA associata al nostro Sci Club?</h4>
    <ul style="list-style: circle; padding: 10px 20px;">
        <li>dovrai recarti con un documento di identità ad una delle casse del comprensorio sciistico Vialattea Italia e comunicare di essere un associato dello SCI CLUB BUSSOLENO e di aver smarrito la skicard associata a questo sci club</li>
        <li>comunicarlo  allo sci club bussoleno il prima possibile scrivendo a <a href="mailto:info@sciclubbussoleno.com">info@sciclubbussoleno.com</a> una email con il nuovo codice</li>

    </ul>
</div>